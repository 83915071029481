import React from 'react';
import PinterestCreativeGroup from './PinterestCreativeGroup';

export class PinterestCreativeGroupBuilder extends React.Component {
  render() {
    const { adGroupIdx, campaignIndex, groups } = this.props;

    return (
      <>
        {groups.map((group, groupIdx) => {
          return (
            <PinterestCreativeGroup
              key={`creative-group-${adGroupIdx}-${groupIdx}`}
              index={groupIdx}
              adGroupIdx={adGroupIdx}
              group={group}
              onAddImages={(blob, type, imageIdx) => {
                this.props.onAddImages(groupIdx, blob, type, imageIdx);
              }}
              onChangeImageTitle={(imageIdx, value) => {
                this.props.onChangeImageTitle(groupIdx, imageIdx, value);
              }}
              onChangeImageDescription={(imageIdx, value) => {
                this.props.onChangeImageDescription(groupIdx, imageIdx, value);
              }}
              removeImage={imageIdx => this.props.removeImage(groupIdx, imageIdx)}
              duplicateImage={imageIdx => this.props.duplicateImage(groupIdx, imageIdx)}
              populateText={imageIdx => this.props.populateText(groupIdx, imageIdx)}
            />
          );
        })}
      </>
    );
  }
}
