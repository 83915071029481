import React, { Component } from 'react';
import Select from 'react-select';
import qs from 'qs';

import {
  getFacebookAccounts,
  getRevcontentAccounts,
  getOutbrainAccounts,
  getFacebookCampaigns,
  getRevcontentCampaigns,
  getOutbrainCampaigns,
  initSession,
  initNewSession,
  startRead,
  getArticleList,
  createCampaign,
  duplicateCampaign,
  getTwitterAccounts,
  getTwitterCampaigns,
  readTwitterCampaign,
  fetchCampaigns,
  fetchBulkCampaigns,
  fetchCampaignsStructure,
  rejectReview,
  upsertSnapshot,
  getSitesPerPlatform as getPlatformSitesFromServer,
  getAdName,
  getCreatives,
  wsCampaignCreate,
  getGoogleAdsAccounts,
  getGoogleAdsCampaigns,
  getGoogleAdsCampaignByName,
  getBlacklistedCreativesList,
  getCreators,
  getTaboolaSubAccounts,
  loadTaboolaBulk,
  loadTaboolaCampaign,
  getTaboolaAccounts,
  getTaboolaCampaign,
  getBaiduAccounts,
  getBaiduCampaign,
  getBaiduCampaigns,
  getOutbrainMetadata,
  loadOutbrainCampaign,
  loadOutbrainBulk,
  getZemantaAccounts,
  getZemantaCampaigns,
  wsCampaignLoad,
  getPinterestAccounts,
  getPinterestCampaigns,
  updateCamp
} from './Api';
import { TaboolaCreate } from './taboola/TaboolaCreate';
import FacebookCreate from './facebook/FacebookCreate';
import FacebookCreate2 from './facebook/FacebookCreate2';
import FacebookReviewCreatives from './facebook/FacebookReviewCreatives';
import FacebookBulk from './facebook/FacebookBulk';
import RevcontentCreate from './revcontent/RevcontentCreate';
import { TwitterCreate, parseTwitterSourceData } from './twitter/TwitterCreate';
import { OutbrainCreate } from './outbrain/OutbrainCreate';
import {
  GLOBAL,
  BD,
  FB,
  TB,
  RC,
  OB,
  TW,
  GA,
  TASKS,
  PLATFORMS,
  COMMANDS,
  REVIEWER,
  PLATFORM_OPTIONS,
  ZM,
  PN
} from './Constants';
import { getPlatformSites, setPlatformSites } from './Sites';
import { parseCampaignName } from './NameParser';
import { getParsedUserOptions } from '../utils/AuthService';
import { toast } from 'react-toastify';
import CustomNotification from './CustomNotification.js';
import FacebookCopy from './facebook/FacebookCopy';
import Loader from './Loader';
import { Grid, Typography, Paper, Button } from '@mui/material';
import { getSelectValue } from './Shared';
import FacebookUploadCreatives from './facebook/FacebookUploadCreatives';
import CreativeNav from './CreativeNav';
import { BaiduCreate } from './baidu/BaiduCreate';
import { TwitterBulk } from './twitter/TwitterBulk';
import { GoogleAdsCreate } from './googleAds/GoogleAdsCreate';
import {
  handleCreateFromCreatives,
  handleCreateFromFbLibrary,
  handleCreateFromInsights,
  handleOpenCampaignsFromInsights
} from './QueryParamHandlers';
import { handleSnapshot } from './SnapshotHandlers';
import styled from '@emotion/styled';
import { BulkSection } from './BulkSection';
import { ZemantaCreate } from './zemanta/ZemantaCreate';
import { PinterestCreate } from './pinterest/PinterestCreate';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: 'auto',
  width: '640px'
}));

const StyledFbPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  width: '99vw',
  fontSize: '0.80rem',
  fontFamily: 'Open Sans',
  fontWeight: '500'
}));

const ALL_PREFIX = '* all *';

class NativeCreate extends Component {
  constructor(props) {
    super(props);
    // ! Important - all TB (and now FB Search) specific state has been pushed to the TB component!
    this.state = {
      platform: '',
      options: {},
      accounts: [],
      subAccounts: [],
      campaigns: [],
      selectedTask: TASKS.CREATE,
      selectedAccount: '',
      selectedSubAccount: '',
      newAccount: '',
      selectedCampaign: '',
      previousSelectedCampaign: '',
      previousSelectedCampaignName: '',
      selectedCampaignName: '',
      articleList: [],
      session: -1,
      ws: null,
      status: '',
      isLoading: false,
      sourceData: null,
      triggerRowUpdate: false,
      triggerSelectorUpdate: false,
      sessionData: {},
      progress: {},
      shortProgress: {},
      lastSuccessfulIndex: {},
      // TODO track session -> create or duplicate: {},
      progressToastIds: {},
      osChoices: [],
      OBinterestsList: [],
      toastId: null,
      twitterReady: false,
      medianetOffers: [],
      filteredCampaigns: [],
      campaignPrefixes: [],
      loadedFromSnapshot: false,
      spmCreativeBankAccess: false,
      spmUploadAccess: false,
      spmReviewAccess: false,
      isCreativesReviewer: false,
      sourceCampaigns: '',
      programOptions: {},
      creativeHashes: '',
      creatorsList: [],
      campaignOptions: [],
      selectedCampaigns: [],
      isBulk: false
    };
    this.countryList = GLOBAL.APP_COUNTRY_LIST.split(',')
      .concat(['the same as original'])
      .map(el => {
        return { label: el, value: el };
      })
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

    this.countryList.push({ label: 'worldwide', value: 'ww' });
    this.rejectCampaignReasonInput = null;
  }

  resume(session, lastSuccess) {
    const resumeOptions = {
      resumeIndex: lastSuccess + 1 || 0
    };
    switch (this.state.sessionData[session].task) {
      case TASKS.CREATE:
        createCampaign(
          this.state.ws,
          session,
          null, // For TB, the server determines the correct destination account
          this.state.platform,
          resumeOptions,
          this.getSnapshotOptions()
        );
        break;
      case TASKS.DUPLICATE:
        duplicateCampaign(
          this.state.ws,
          session,
          null, // For TB, the server determines the correct destination account
          this.state.platform,
          resumeOptions,
          null,
          null,
          null,
          null,
          this.getSnapshotOptions()
        );
        break;
      case TB.DUPLICATE_WITH_DATA_SEGMENTS:
        duplicateCampaign(
          this.state.ws,
          session,
          null, // For TB, the server determines the correct destination account
          this.state.platform,
          resumeOptions,
          null,
          null,
          null,
          null,
          this.getSnapshotOptions()
        );
        break;
      default:
        throw new Error('Unknown task for session');
    }
  }
  initWebSocket = () => {
    if (!this.state.ws || this.state.ws.readyState !== WebSocket.OPEN) {
      // only initialize if the connection is not open or ws doesn't exist
      initSession(
        (data, cmd) => this.messageHandler(data, cmd),
        ws => this.wsHandler(ws)
      );
    }
  };
  wsHandler(wsObject) {
    this.setState({ ws: wsObject });
    if (!wsObject) {
      this.props.autoReload();
    }
  }

  messageHandler(data, cmd) {
    console.log({ data });
    if (data && data.error) {
      toast.error(`${data.error}`);
      this.setState({ isLoading: false });
      return;
    }
    if (data && data?.result?.error) {
      toast.error(`${data.result.error}`);
      this.setState({ isLoading: false });
      return;
    }
    if (data && data?.result) {
      this.setState({ isLoading: false, sourceData: data.result });
      return;
    }
    if (data && data.echo === COMMANDS.LOAD_BULK_CAMPAIGNS) {
      this.setState({ sourceData: data.result, isBulk: true, isLoading: false });
      return;
    }
    if (data && data.echo === COMMANDS.LOAD_CAMPAIGN) {
      this.setState({ sourceData: data, isLoading: false });
      return;
    }
    switch (cmd) {
      case COMMANDS.LOG:
        this.setState({ status: data });
        break;
      case COMMANDS.INIT_SESSION:
        // Keep current session index in state
        console.log('Setting session index');
        this.setState({ session: data });
        break;
      case COMMANDS.SOURCE_DATA:
        console.log('Got native source data');
        console.log(data);
        this.setState({ isLoading: false });
        // we don't need to set source data if campaign loaded from snapshot
        // but we still need to read it from server
        // (side effect)

        if (this.state.loadedFromSnapshot) {
          break;
        }
        this.setState({ sourceData: data });
        this.processNativeData(data);
        break;
      // TODO temporarily discard create_campaigns return result, until we fix write_to_history flow
      case COMMANDS.CREATE_CAMPAIGNS:
        console.log('Skipping create_campaigns message');
        break;
      case COMMANDS.UPSERT_SNAPSHOT:
        console.log('Got UPSERT_SNAPSHOT');
        console.log(JSON.stringify(data));
        if (!data.updated) {
          alert(`Snapshot url: ${data.location}`);
        }
        break;
      case COMMANDS.FETCH_CAMPAIGNS:
        console.log('Fetch campaigns', data);
        if (data.error) {
          if (data.error.includes('Incorrect campaign name')) {
            toast.error(`Incorrect campaign name ${this.state.selectedCampaignName}`);
            this.setState({
              isLoading: false
            });
          } else {
            toast.error(`Can't read campaign source data ${this.state.selectedCampaign}`);
          }
          this.setState({
            isLoading: false
          });
          return;
        } else {
          this.setState({
            isLoading: false,
            sourceData: data
          });
        }
        if (this.state.loadedFromSnapshot) {
          this.setState({ isLoading: false });
          break;
        }
        break;
      case COMMANDS.FETCH_BULK_CAMPAIGNS:
        if (data.error) {
          toast.error(`Error: can't read campaigns source data. Please, check if all campaigns are valid.`);
          console.log('Error: ', data.error);
          this.setState({
            isLoading: false
          });
        } else {
          if (!data.withErrors.length) {
            console.log({ data });
            // toast.success('Successfully read campaigns source data');
          } else {
            toast.success(
              `Fetched ${data.campaigns.length} of ${data.campaigns.length +
                data.withErrors.length} campaigns.\nCan't read ${data.withErrors.join()}`
            );
          }
          this.setState({
            isLoading: false,
            sourceData: data.campaigns
          });
        }
        break;
      case COMMANDS.FETCH_CAMPAIGNS_STRUCTURE:
        console.log('Got campaign structure');
        data[0].accountId = this.state.selectedAccount;
        data[0].prefix = this.state.options.prefix;
        if (this.state.loadedFromSnapshot) {
          this.setState({ isLoading: false });
          break;
        }

        this.setState({
          isLoading: false,
          sourceData: data[0]
        });
        break;
      case COMMANDS.TRACE:
        this.props.trace(data);
        break;
      case COMMANDS.TRACES:
        this.props.trace(data);
        break;
      case COMMANDS.PROGRESS: {
        let sessionData = this.state.sessionData;
        if (!sessionData[data.session].progress) {
          sessionData[data.session].progress = '';
        }
        if (data.data.successIndex || data.data.successIndex === 0) {
          sessionData[data.session].lastSuccessfulIndex = data.data.successIndex;
        }
        sessionData[data.session].progress += data.data.message + '\n';
        sessionData[data.session].shortProgress = data.data.shortMessage;
        this.setState(
          {
            sessionData: sessionData
          },
          () => {
            const newToastObject = {
              render: (
                <CustomNotification
                  shortText={this.state.sessionData[data.session].shortProgress}
                  longText={this.state.sessionData[data.session].progress}
                  error={data.data.error}
                  resume={() => this.resume(data.session, this.state.sessionData[data.session].lastSuccessfulIndex)}
                />
              ),
              closeOnClick: false
            };
            if (data.data.progress) {
              newToastObject.progress = data.data.progress;
            }
            if (data.data.end) {
              newToastObject.type = toast.TYPE.SUCCESS;
            } else if (data.data.error) {
              newToastObject.type = toast.TYPE.ERROR;
            } else {
              newToastObject.type = toast.TYPE.WARNING;
            }
            if (this.state.toastIdProgressPairs && this.state.toastIdProgressPairs.length > 0) {
              this.state.toastIdProgressPairs.forEach(pairs => {
                toast.update(pairs.toastId, newToastObject);
              });
              if (data.data.end) {
                toast.success(`Success: ${data.data.shortMessage}`);
                toast.update(this.state.progressToastIds[data.session], newToastObject);
              }
            } else {
              toast.update(this.state.progressToastIds[data.session], newToastObject);
            }
          }
        );
        break;
      }
      case COMMANDS.UPDATE_CAMPAIGNS:
        this.setState({
          isLoading: false
        });
        if (
          data.error ||
          (data.result &&
            typeof data.result === 'string' &&
            data.result.toUpperCase() !== 'SUCCESS' &&
            data.result.toUpperCase() !== 'OK')
        ) {
          if (data.data && Array.isArray(data.data)) {
            this.setState({ status: data.data.map(x => x.error).join('\n---\n') });
          }
          toast.error('Error: ' + (data.error || data.result));
        } else {
          this.setState({ status: '' });
          toast.success(' ' + data.result);
        }
        break;
      case COMMANDS.COPY_CAMPAIGN: {
        if (data.error) {
          console.log('Copy campaign error', data);
          toast.error('Error: ' + (data.error || data.result));
        } else {
          console.log('Copy campaign finished', data);
          toast.success('Copy campaign finished');
        }
        break;
      }
      case COMMANDS.END:
        this.setState({
          isLoading: false,
          status: data
        });
        break;
      default:
        if (data && data.error) {
          toast.error('Error: ' + data.error);
        } else {
          throw new Error('Unknown command ' + cmd);
        }
    }
  }

  onTest() {
    const fbcall = [
      {
        prefix: 'lb',
        geoType: 'include',
        geoName: 'ww',
        duplicationType: 'group',
        site_code: 'fih',
        account: 'act_477173814184991',
        mobilePlatforms: 'Android,iOS',
        budget: '10',
        bid_amount: 0,
        accountCodeName: 'ek20b2b',
        campaignNote: 'footlove1w / purchase-s2s / All',
        genders: 0,
        age_min: 18,
        age_max: 65,
        status: 'ACTIVE',
        conversion: 'capi_purchase',
        objective: 'CONVERSIONS',
        pacing_type: ['standard'],
        bid_strategy: 'LOWEST_COST_WITH_MIN_ROAS',
        billing_event: 'IMPRESSIONS',
        campaign: {
          adsets: [
            {
              type: 'SINGLE',
              ads: [
                {
                  text:
                    'After learning of his AIDS diagnosis, Hudson had a friend mail anonymous letters to four lovers\nRock Hudson was one of America’s most beloved and scandalous figures in history, playing in over 70 films and television shows. But more than he was known for his acting talent, everyone wanted to know about his personal life—and most specifically his preferences. Over thirty years after ...',
                  title: 'Rock Hudson sent letters to his lovers after he was diagnosed with AIDS',
                  image: 'https://s3.amazonaws.com/shinez-pictures/166828011242911f858577ca7af6b5b5178a691dc34c6.jpg',
                  fromCreativeBank: true
                }
              ]
            }
          ]
        },
        platform: 'a',
        widgetCodeName: 'rockh',
        widgetLanguageCode: 'en',
        widgetVersion: 'shz',
        article: 'rockhen',
        widget_id: 'BBe3t7V4OcKwYMYs2oqik',
        widget_path: 'americas-favorite-1950s-leading',
        widget_title: '',
        country: 'ww',
        freeText: '',
        language: '1001',
        times: 1,
        sourceAccount: 'loading...',
        adNaming: 'Default',
        qs: '',
        budgetType: 'daily campaign',
        autoPlacements: false,
        useTrackingInUrl: false,
        start_time: '2022-11-15 00:00:00 EST',
        campaignCreator: '',
        sourceAds: [],
        bid_constraints: {
          roas_average_floor: 10
        },
        sourceCampaignName: ''
      }
    ];
    createCampaign(this.state.ws, 700, null, 'Facebook', fbcall, null, true);
  }

  setCreativesByIds(ids) {
    setTimeout(() => {
      const creativeIds = ids.trim().split(',');
      const blacklistedCreatives = this.state.blacklistedCreatives;
      const filteredIds = creativeIds.filter(id => blacklistedCreatives[id] !== true);

      if (filteredIds.length !== creativeIds.length) {
        const blockedCount = creativeIds.length - filteredIds.length;
        toast.warn(
          `${blockedCount} creative id${blockedCount !== 1 ? 's are' : ' is'} blocked. Removed from the queue.`
        );
      }
      getCreatives(filteredIds.join()).then(xs => {
        this.setState({ isLoading: false });
        if (xs.length > 0) {
          toast.success(`Got ${xs.length} creatives.`);
          this.setState({ creativeFromInsights: xs });
        } else {
          toast.error(`Error: can't find insight ${ids}.`);
        }
      });
    }, 1000);
  }

  async creativesAccess(options) {
    if (options.spm_upload_access || options.spm_review_access) {
      options.platforms = (options.platforms || []).concat(['facebook_upload_creatives', 'facebook_review_creatives']);
      this.setState({ programOptions: options });
    }
    if (options.spm_creative_bank_access) {
      this.setState({ spmCreativeBankAccess: options.spm_creative_bank_access });
    }
    if (options.spm_upload_access) {
      this.setState({ spmUploadAccess: options.spm_upload_access });
    }
    if (options.spm_review_access) {
      this.setState({ spmReviewAccess: options.spm_review_access });
    }
    if (options.spm_reviewer_access) {
      this.setState({ isCreativesReviewer: options.spm_reviewer_access });
    }
  }

  async componentDidMount() {
    this.initWebSocket();

    const options = await getParsedUserOptions();
    options.platforms = options.platforms.concat('pinterest');
    this.setState({
      programOptions: options,
      user: options.email
    });
    await this.creativesAccess(options);

    getCreators().then(creators => {
      if (!options || !options.ma_allowed_creator_groups_list) {
        creators = [];
      } else if (options.ma_allowed_creator_groups_list.length > 0) {
        creators = creators.filter(creator => options.ma_allowed_creator_groups_list.includes(creator.group));
      }
      this.setState({ creatorsList: creators });
    });

    const queryParams = qs.parse(window.location.search.replace('?', ''));

    const platformSites = await getPlatformSitesFromServer();
    //need for FB.ALL_SITES_ACCOUNT_LIST
    this.notFilteredSiteList = platformSites.default;
    setPlatformSites(platformSites);

    const maSiteList = options.ma_site_list;
    const getFilteredPlatformSites = platform => {
      let sites = getPlatformSites(platform).filter(el => el.enabled);

      if (maSiteList && maSiteList.length > 0) {
        sites = sites.filter(el => maSiteList.includes(el.code));
      }

      return sites.map(el => {
        return { label: el.code + ' (' + el.name + ')', value: el.code };
      });
    };

    this.TbFilteredSiteList = getFilteredPlatformSites(PLATFORMS.TABOOLA).filter(
      el => !TB.EXCLUDE_SITES.includes(el.value)
    );
    this.RcFilteredSiteList = getFilteredPlatformSites(PLATFORMS.REVCONTENT).filter(
      el => !RC.EXCLUDE_SITES.includes(el.value)
    );
    this.ObFilteredSiteList = getFilteredPlatformSites(PLATFORMS.OUTBRAIN).filter(
      el => !OB.EXCLUDE_SITES.includes(el.value)
    );
    this.TwFilteredSiteList = getFilteredPlatformSites(PLATFORMS.TWITTER).filter(
      el => !TW.EXCLUDE_SITES.includes(el.value)
    );
    this.FbFilteredSiteList = getFilteredPlatformSites(PLATFORMS.FACEBOOK).filter(
      el => !FB.EXCLUDE_SITES.includes(el.value)
    );
    this.BdFilteredSiteList = getFilteredPlatformSites(PLATFORMS.BAIDU).filter(
      el => !BD.EXCLUDE_SITES.includes(el.value)
    );
    this.GaFilteredSiteList = getFilteredPlatformSites(PLATFORMS.GOOGLE_ADS).filter(
      el => !GA.EXCLUDE_SITES.includes(el.value)
    );
    this.PnFilteredSiteList = getFilteredPlatformSites(PLATFORMS.PINTEREST).filter(
      el => !GA.EXCLUDE_SITES.includes(el.value)
    );
    this.ZmFilteredSiteList = getFilteredPlatformSites(PLATFORMS.ZEMANTA).filter(
      el => !ZM.EXCLUDE_SITES.includes(el.value)
    );
    this.allPlatformSites = platformSites;
    await this.loadAdditionalData();

    if ('snapshotId' in queryParams) {
      await handleSnapshot(this, queryParams.snapshotId);
    }
    // /?createFromInsights&platform=facebook&id=23857685994800564
    else if ('createFromInsights' in queryParams) {
      handleCreateFromInsights(this, queryParams);
      // TODO check for usage, remove if not
    } else if ('createFromCreatives' in queryParams) {
      handleCreateFromCreatives(this, queryParams);
    }
    // /?openCampaignsFromInsights&campaigns=dr-us-a-trm-bbuncen-3612-e9-t3-ch-dn3&platform=facebook
    else if ('openCampaignsFromInsights' in queryParams) {
      await handleOpenCampaignsFromInsights(this, queryParams);
    }
    // /?createFromFbLibrary&id=64d4f6b86d89de320a0aca13
    else if ('createFromFbLibrary' in queryParams) {
      handleCreateFromFbLibrary(this, queryParams);
    } else if (options && options.default_platform && options.spm_access) {
      this.getAccounts(PLATFORMS[options.default_platform.toUpperCase()]);
      this.setPlatformAndOptions(PLATFORMS[options.default_platform.toUpperCase()]);
    }
    this.toggleScroll();
  }

  componentDidUpdate() {
    this.toggleScroll();
  }

  componentWillUnmount() {
    if (this.state.ws) {
      this.state.ws.close();
    }
  }
  /**
   * used to prevent scrolling while Loader is on screen
   */
  toggleScroll() {
    if (this.state.isLoading) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }
  }

  async loadAdditionalData() {
    const clv = await getArticleList();
    const wid = {};
    for (const i in clv) {
      const art = clv[i];
      wid[art.wid] = art;
    }

    const metadata = await getOutbrainMetadata();

    const OBinterestsList = Object.keys(metadata.interest)
      .map(key => ({
        label: metadata.interest[key],
        value: key
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    console.log({ OBinterestsList });
    const blacklistedCreatives = await getBlacklistedCreativesList();
    return new Promise(resolve => {
      this.setState(
        {
          OBinterestsList,
          articleList: { clv, wid },
          blacklistedCreatives
        },
        resolve
      );
    });
  }

  async useUserOptions() {
    const options = await getParsedUserOptions(this.state.platform);
    // if (options.prefix) {
    //   this.modifyOptions('prefix', options.prefix);
    // }
    if (options.spm_prefix_default) {
      this.modifyOptions('spm_prefix_default', options.spm_prefix_default);
    }

    if (options.defaultLanguage) {
      this.modifyOptions('defaultLanguage', options.defaultLanguage);
    }
  }

  setPlatformAndOptions(platform) {
    console.log(`Setting default options for platform `, platform);
    let defaultOptions = {};
    switch (platform) {
      case PLATFORMS.REVCONTENT:
        defaultOptions[RC.KEY_BUDGET] = RC.DEFAULT_BUDGET;
        defaultOptions[RC.KEY_DEFAULT_BID] = RC.DEFAULT_BID;
        defaultOptions[RC.KEY_CREATIVE_GROUPS] = [];
        defaultOptions[RC.KEY_BIDS] = [];
        defaultOptions[RC.KEY_SITE] = RC.DEFAULT_SITE;
        defaultOptions[RC.KEY_PREFIX] =
          this.state.programOptions.spm_prefix_for_revcontent || this.state.programOptions.spm_prefix_default;
        break;
      case PLATFORMS.TWITTER:
        defaultOptions[TW.KEY_BUDGET] = TW.DEFAULT_BUDGET;
        defaultOptions[TW.KEY_DEFAULT_BID] = TW.DEFAULT_BID;
        defaultOptions[TW.KEY_CREATIVE_GROUPS] = [];
        defaultOptions[TW.KEY_BIDS] = [];
        defaultOptions[TW.KEY_SITE] = TW.DEFAULT_SITE;
        defaultOptions[TW.KEY_COUNTRY] = TW.DEFAULT_COUNTRY;
        defaultOptions[TW.KEY_PREFIX] =
          this.state.programOptions.spm_prefix_for_twitter || this.state.programOptions.spm_prefix_default;
        break;
      case PLATFORMS.TWITTER_BULK:
        defaultOptions[TW.KEY_BUDGET] = TW.DEFAULT_BUDGET;
        defaultOptions[TW.KEY_DEFAULT_BID] = TW.DEFAULT_BID;
        defaultOptions[TW.KEY_CREATIVE_GROUPS] = [];
        defaultOptions[TW.KEY_BIDS] = [];
        defaultOptions[TW.KEY_SITE] = TW.DEFAULT_SITE;
        defaultOptions[TW.KEY_AGE] = TW.DEFAULT_AGE;
        defaultOptions[TW.KEY_COUNTRY] = TW.DEFAULT_COUNTRY;
        defaultOptions[TW.KEY_PREFIX] =
          this.state.programOptions.spm_prefix_for_twitter || this.state.programOptions.spm_prefix_default;

        break;
      case PLATFORMS.FACEBOOK:
        this.selectPlatform(PLATFORMS.FACEBOOK);
        break;
      case PLATFORMS.FACEBOOK2:
        this.selectPlatform(PLATFORMS.FACEBOOK2);
        break;
      case PLATFORMS.FACEBOOK_REVIEW_CREATIVES:
        this.selectPlatform(PLATFORMS.FACEBOOK_REVIEW_CREATIVES);
        break;
      default:
        break;
    }
    this.setState(
      {
        options: defaultOptions,
        platform: platform,
        selectedTask: TASKS.CREATE,
        selectedAccount: '',
        selectedCampaign: ''
      },
      async () => {
        await this.useUserOptions();
      }
    );
  }

  setDefaultOptions(platform) {
    let defaultOptions = {};
    switch (platform) {
      case PLATFORMS.REVCONTENT:
        defaultOptions[RC.KEY_BUDGET] = RC.DEFAULT_BUDGET;
        defaultOptions[RC.KEY_DEFAULT_BID] = RC.DEFAULT_BID;
        defaultOptions[RC.KEY_CREATIVE_GROUPS] = [];
        defaultOptions[RC.KEY_BIDS] = [];
        defaultOptions[RC.KEY_SITE] = RC.DEFAULT_SITE;
        defaultOptions[RC.KEY_PREFIX] =
          this.state.programOptions.spm_prefix_for_revcontent || this.state.programOptions.spm_prefix_default;

        break;
      case PLATFORMS.TWITTER:
        defaultOptions[TW.KEY_BUDGET] = TW.DEFAULT_BUDGET;
        defaultOptions[TW.KEY_DEFAULT_BID] = TW.DEFAULT_BID;
        defaultOptions[TW.KEY_CREATIVE_GROUPS] = [];
        defaultOptions[TW.KEY_BIDS] = [];
        defaultOptions[TW.KEY_SITE] = TW.DEFAULT_SITE;
        defaultOptions[TW.KEY_COUNTRY] = TW.DEFAULT_COUNTRY;
        defaultOptions[TW.KEY_PREFIX] =
          this.state.programOptions.spm_prefix_for_twitter || this.state.programOptions.spm_prefix_default;
        break;
      case PLATFORMS.TWITTER_BULK:
        defaultOptions[TW.KEY_BUDGET] = TW.DEFAULT_BUDGET;
        defaultOptions[TW.KEY_DEFAULT_BID] = TW.DEFAULT_BID;
        defaultOptions[TW.KEY_CREATIVE_GROUPS] = [];
        defaultOptions[TW.KEY_BIDS] = [];
        defaultOptions[TW.KEY_SITE] = TW.DEFAULT_SITE;
        defaultOptions[TW.KEY_AGE] = TW.DEFAULT_AGE;
        defaultOptions[TW.KEY_COUNTRY] = TW.DEFAULT_COUNTRY;
        defaultOptions[TW.KEY_PREFIX] =
          this.state.programOptions.spm_prefix_for_twitter || this.state.programOptions.spm_prefix_default;
        break;
      default:
        break;
    }
    this.setState(
      {
        options: defaultOptions
      },
      () => {
        this.useUserOptions();
      }
    );
  }

  processNativeData(data) {
    if (this.state.platform === PLATFORMS.REVCONTENT) {
      const options = this.state.options;
      const sourceGroup = {
        images: [],
        imageHashes: [],
        texts: [],
        tooltips: []
      };
      data.itemData.forEach(item => {
        sourceGroup.images.push(item.image_url);
        sourceGroup.imageHashes.push(item.image_url);
        sourceGroup.texts.push(item.headline);
      });
      options[RC.KEY_BIDS] = [];
      options[RC.KEY_CREATIVE_GROUPS] = [sourceGroup];
      options[RC.KEY_BID_TYPE] = data.campaignData.bid_type;
      options[RC.KEY_BUDGET] = data.campaignData.budget;
      //options[RC.KEY_BUDGET_AMOUNT] = data.campaignData.budget_amount;
      options[RC.KEY_SPENDING_LIMIT_MODEL] = data.campaignData.optimize;
      options[RC.KEY_STATUS] = data.campaignData.enabled;
      const parsedName = parseCampaignName(this.state.platform, data.campaignData.name);
      options[GLOBAL.KEY_WIDGET_CODE_NAME] = parsedName.widgetCodeName;
      options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE] = parsedName.widgetLanguageCode;
      options[RC.KEY_SITE] = parsedName.site;
      options[RC.KEY_COUNTRY] = parsedName.country;
      options[RC.KEY_DEVICE] = [];
      options[RC.KEY_OS] = [];
      if (parsedName.platform === 'd') {
        options[RC.KEY_DEVICE].push(RC.DESKTOP);
      } else {
        if (data.campaignData.device_targeting.indexOf(RC.API_MOBILE) !== -1) {
          options[RC.KEY_DEVICE].push(RC.MOBILE);
        }
        if (data.campaignData.device_targeting.indexOf(RC.API_TABLET) !== -1) {
          options[RC.KEY_DEVICE].push(RC.TABLET);
        }
        if (data.campaignData.device_targeting.indexOf(RC.API_IOS) !== -1) {
          options[RC.KEY_OS].push(RC.IOS);
        }
        if (data.campaignData.device_targeting.indexOf(RC.API_ANDROID) !== -1) {
          options[RC.KEY_OS].push(RC.ANDROID);
        }
      }
      options[RC.KEY_DEVICE] = options[RC.KEY_DEVICE].join(',');
      options[RC.KEY_OS] = options[RC.KEY_OS].join(',');

      this.setState({
        options: options,
        triggerRowUpdate: !this.state.triggerRowUpdate, // to redraw the bid rows
        triggerSelectorUpdate: !this.state.triggerSelectorUpdate // to calculate widgetid
      });
    } else if (this.state.platform === PLATFORMS.TWITTER) {
      if (!data.line_items) {
        toast.error(`Error: can't get data from Twitter for this campaign.`);
        this.setState({
          triggerRowUpdate: !this.state.triggerRowUpdate, // to redraw the bid rows
          triggerSelectorUpdate: !this.state.triggerSelectorUpdate, // to calculate widgetid
          isLoading: false
        });
      } else {
        const newOptions = parseTwitterSourceData(this.state.options, data);
        this.setState({
          options: newOptions,
          triggerRowUpdate: !this.state.triggerRowUpdate, // to redraw the bid rows
          triggerSelectorUpdate: !this.state.triggerSelectorUpdate, // to calculate widgetid
          isLoading: false
        });
      }
    }
  }
  getAccounts(newPlatform) {
    const { programOptions } = this.state;
    if (newPlatform === PLATFORMS.TWITTER || newPlatform === PLATFORMS.TWITTER_BULK) {
      getTwitterAccounts().then(accounts => {
        this.setState({
          accounts: accounts.map(el => ({
            id: el.id,
            desc: el.name
              // .replace(/\s/g, '')
              .replace(/\./g, '')
              .replace(/-/g, '')
              .replace('shinezioltd', '')
          })),
          twitterReady: true
        });
      });
    } else {
      this.setState({
        twitterReady: false
      });
    }
    if (
      newPlatform === PLATFORMS.FACEBOOK ||
      newPlatform === PLATFORMS.FACEBOOK_COPY ||
      newPlatform === PLATFORMS.FACEBOOK_BULK
    ) {
      getFacebookAccounts().then(res => {
        if (programOptions.isSwag) {
          res = res.filter(acc => !acc.desc.toLowerCase().includes('b2b'));
        } else {
          //additional reinsurance for B2B users, independent of permissions
          const userPrefixes =
            typeof programOptions.prefix === 'string' ? programOptions.prefix.split(',') : programOptions.prefix;
          res = res.filter(acc => {
            if (acc.user_prefix) {
              const accPrefixes = typeof acc.user_prefix === 'string' ? acc.user_prefix.split(',') : acc.user_prefix;
              for (let prefix of userPrefixes) {
                if (accPrefixes.includes(prefix)) {
                  return true;
                }
              }
              return false;
            } else {
              return true;
            }
          });
        }
        this.setState({
          accounts: res
        });
      });
    }
    if (newPlatform === PLATFORMS.FACEBOOK2) {
      getFacebookAccounts().then(res => {
        if (programOptions.isSwag) {
          res = res.filter(acc => !acc.desc.toLowerCase().includes('b2b'));
        }
        this.setState({
          accounts: res
        });
      });
    }
    if (newPlatform === PLATFORMS.GOOGLE_ADS) {
      getGoogleAdsAccounts().then(res => {
        let workAccounts = [];
        for (let acc in res) {
          workAccounts.push({ desc: res[acc].name, id: res[acc].id });
        }
        this.setState({
          accounts: workAccounts
        });
      });
    }
    if (newPlatform === PLATFORMS.REVCONTENT) {
      getRevcontentAccounts().then(res => {
        this.setState({
          accounts: res
        });
      });
    }
    if (newPlatform === PLATFORMS.TABOOLA) {
      getTaboolaAccounts().then(res => {
        this.setState({
          accounts: res
        });
      });
      getTaboolaSubAccounts().then(res => {
        this.setState({
          subAccounts: res
        });
      });
    }
    if (newPlatform === PLATFORMS.OUTBRAIN) {
      getOutbrainAccounts().then(res => {
        let workAccounts = Object.keys(res).map(key => ({
          desc: res[key].name,
          id: res[key].id,
          autoselect: res[key].autoselect
        }));
        this.setState({
          accounts: workAccounts
        });
      });
    }
    if (newPlatform === PLATFORMS.BAIDU) {
      getBaiduAccounts().then(res => {
        let workAccounts = Object.keys(res).map(key => ({
          desc: res[key].account_name,
          id: res[key].account_id
        }));
        console.log({ workAccounts });
        this.setState({
          accounts: workAccounts
        });
      });
    }
    if (newPlatform === PLATFORMS.ZEMANTA) {
      getZemantaAccounts().then(res => {
        let workAccounts = Object.keys(res).map(key => ({
          desc: res[key].name,
          id: res[key].id,
          autoselect: res[key].autoselect
        }));
        this.setState({
          accounts: workAccounts
        });
      });
    }
    if (newPlatform === PLATFORMS.PINTEREST) {
      getPinterestAccounts()
        .then(accounts => accounts.filter(({ token }) => token === 'SWAG'))
        .then(filteredAccounts => this.setState({ accounts: filteredAccounts }));
    }
  }

  selectPlatform(newPlatform) {
    toast.dismiss();
    this.setState(
      {
        platform: newPlatform,
        selectedTask: TASKS.CREATE,
        selectedAccount: '',
        selectedCampaign: ''
      },
      () => {
        if (newPlatform === PLATFORMS.FACEBOOK_COPY) {
          this.setState({ selectedTask: TASKS.DUPLICATE });
        }
      }
    );
    this.getAccounts(newPlatform);
    this.setDefaultOptions(newPlatform);

    //clear query params when try to change platform after using external links to spm
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    if (queryParams && queryParams.platform && newPlatform !== queryParams.platform) {
      window.history.replaceState(null, null, '/');
    }
  }

  selectTask(e) {
    const newTask = e;
    this.setState({
      selectedTask: newTask,
      selectedAccount: '',
      selectedCampaign: '',
      isBulk: false,
      selectedCampaigns: [],
      campaignOptions: [],
      sourceCampaigns: '',
      sourceData: null
    });

    if (
      this.state.platform === PLATFORMS.TABOOLA &&
      (newTask === TASKS.DUPLICATE || newTask === TB.DUPLICATE_WITH_DATA_SEGMENTS)
    ) {
      getTaboolaAccounts().then(res => {
        this.setState({
          accounts: res
        });
      });
    } else if (this.state.platform === PLATFORMS.BAIDU && newTask === TASKS.DUPLICATE) {
      getBaiduAccounts().then(res => {
        let workAccounts = [];
        for (let acc in res) {
          workAccounts.push({ desc: res[acc].account_name, id: res[acc].account_id });
        }
        console.log({ workAccounts });
        this.setState({
          accounts: workAccounts
        });
      });
    } else if (this.state.platform === PLATFORMS.BAIDU && newTask === TASKS.CREATE) {
      this.setDefaultOptions(PLATFORMS.BAIDU);
      this.setState({ status: '' });
    } else if (this.state.platform === PLATFORMS.TWITTER && newTask === TASKS.CREATE) {
      this.setDefaultOptions(PLATFORMS.TWITTER);
    } else if (this.state.platform === PLATFORMS.OUTBRAIN && newTask === TASKS.DUPLICATE) {
      getOutbrainAccounts().then(res => {
        let workAccounts = Object.keys(res).map(key => ({
          desc: res[key].name,
          id: res[key].id
        }));
        this.setState({
          accounts: workAccounts
        });
      });
    } else if (this.state.platform === PLATFORMS.REVCONTENT && newTask === TASKS.DUPLICATE) {
      getRevcontentAccounts().then(res => {
        this.setState({
          accounts: res
        });
      });
    }
    if (this.state.platform === PLATFORMS.GOOGLE_ADS) {
      getGoogleAdsAccounts().then(res => {
        let workAccounts = [];
        for (let acc in res) {
          workAccounts.push({ desc: res[acc].name, id: res[acc].id });
        }

        this.setState({
          accounts: workAccounts
        });
      });
      if (newTask === TASKS.CREATE) {
        this.setDefaultOptions(PLATFORMS.GOOGLE_ADS);
      }
    }
  }

  selectAccount(e) {
    return new Promise(resolve => {
      const newAccount = e;
      let defaultPrefix = ALL_PREFIX;

      this.setState(
        {
          selectedAccount: newAccount,
          newAccount: true,
          campaigns: [],
          isLoading: this.isDuplicate(),
          selectedCampaign: ''
        },
        () => {
          if (this.state.platform === PLATFORMS.BAIDU) {
            getBaiduCampaigns(this.state.selectedAccount).then(camps => {
              let preparedCamps = [];
              if (camps && Array.isArray(camps)) {
                preparedCamps = camps.map(el => {
                  return {
                    id: el.id,
                    desc: el.id + ' (' + el.name + ')',
                    name: el.name
                  };
                });
                this.setState(
                  { campaigns: preparedCamps, campaignPrefixes: this.getPrefixes(preparedCamps), isLoading: false },
                  () => {
                    this.selectPrefix(defaultPrefix);
                    resolve();
                  }
                );
              }
            });
          }
          if (this.state.platform === PLATFORMS.GOOGLE_ADS) {
            getGoogleAdsCampaigns(newAccount).then(camps => {
              let preparedCamps = [];
              preparedCamps = camps.map(el => {
                return {
                  id: el.campaign.id,
                  desc: el.campaign.id + ' (' + el.campaign.name + ')',
                  name: el.campaign.name
                };
              });
              this.setState(
                { campaigns: preparedCamps, campaignPrefixes: this.getPrefixes(preparedCamps), isLoading: false },
                () => {
                  this.selectPrefix(defaultPrefix);
                  resolve();
                }
              );
            });
          }
          if (newAccount && this.state.platform !== PLATFORMS.GOOGLE_ADS) {
            if (this.state.platform === PLATFORMS.TWITTER) {
              getTwitterCampaigns(newAccount).then(camps => {
                let preparedCamps = [];
                for (let id in camps) {
                  preparedCamps.push({
                    id: id,
                    desc: camps[id],
                    name: camps[id]
                  });
                }
                this.setState(
                  { campaigns: preparedCamps, campaignPrefixes: this.getPrefixes(preparedCamps), isLoading: false },
                  () => {
                    this.selectPrefix(defaultPrefix);
                    resolve();
                  }
                );
              });
            } else {
              const getCampaigns = {
                [PLATFORMS.FACEBOOK]: getFacebookCampaigns,
                [PLATFORMS.FACEBOOK2]: getFacebookCampaigns,
                [PLATFORMS.FACEBOOK_COPY]: getFacebookCampaigns,
                [PLATFORMS.FACEBOOK_BULK]: getFacebookCampaigns,
                fb: getFacebookCampaigns,
                [PLATFORMS.OUTBRAIN]: getOutbrainCampaigns,
                [PLATFORMS.REVCONTENT]: getRevcontentCampaigns,
                [PLATFORMS.ZEMANTA]: getZemantaCampaigns,
                [PLATFORMS.PINTEREST]: getPinterestCampaigns
              };
              getCampaigns[this.state.platform]?.(newAccount).then(camps => {
                let preparedCamps = [];
                preparedCamps = camps.map(el => {
                  return { id: el.id, subAcc: el.adv_id, desc: el.id + ' (' + el.name + ')', name: el.name };
                });
                this.setState(
                  { campaigns: preparedCamps, campaignPrefixes: this.getPrefixes(preparedCamps), isLoading: false },
                  () => {
                    this.selectPrefix(defaultPrefix);
                    resolve();
                  }
                );
                if (this.state.platform === PLATFORMS.FACEBOOK && !this.state.loadedFromSnapshot) {
                  this.selectDestinationAccount(newAccount);
                }
              });
            }
          } else {
            resolve();
          }
        }
      );
    });
  }
  selectSubAccount(e) {
    return new Promise(resolve => {
      const newSubAccount = e;
      let defaultPrefix = ALL_PREFIX;

      this.setState(
        {
          selectedSubAccount: newSubAccount,
          newAccount: true,
          campaigns: [],
          isLoading: this.isDuplicate(),
          selectedCampaign: ''
        },
        () => {
          if (this.state.platform === PLATFORMS.TABOOLA) {
            const subAcc = this.state.selectedSubAccount;
            getTaboolaCampaign(TB.SHINEZ_NETWORK, subAcc).then(camps => {
              let preparedCamps = [];
              preparedCamps = camps.map(el => {
                return {
                  id: el.id,
                  subAcc,
                  desc: el.id + ' (' + el.name + ')',
                  name: el.name
                };
              });
              this.setState(
                { campaigns: preparedCamps, campaignPrefixes: this.getPrefixes(preparedCamps), isLoading: false },
                () => {
                  this.selectPrefix(defaultPrefix);
                  resolve();
                }
              );
            });
          }
        }
      );
    });
  }

  selectPrefix(prefix) {
    const filteredCampaigns =
      prefix === ALL_PREFIX
        ? this.state.campaigns
        : this.state.campaigns.filter(campaign => prefix === this.getPrefix(campaign.name));

    this.setState({
      selectedPrefix: prefix,
      filteredCampaigns: filteredCampaigns
    });
  }

  getPrefixes(campaigns) {
    const prefixes = campaigns.map(campaign => this.getPrefix(campaign.name));
    const uniquePrefixes = Array.from(this.groupBy(prefixes).keys()).filter(prefix => prefix.length === 2);
    return [ALL_PREFIX, ...uniquePrefixes];
  }

  getPrefix(campaignName) {
    return campaignName.split('-', 1)[0];
  }

  getCampaignDescriptionPrefix(campaignDescription) {
    const campaignName = campaignDescription.split('(')[1] || '';
    return this.getPrefix(campaignName);
  }

  groupBy(array, selector) {
    return array.reduce((result, e) => {
      const key = selector ? e[selector] : e;
      return result.set(key, null);
    }, new Map());
  }

  selectDestinationAccount(destinationAccount) {
    const options = { ...this.state.options, [GLOBAL.KEY_DESTINATION_ACCOUNT]: destinationAccount };
    this.setState({
      options
    });
  }

  selectCampaign(newCampaign) {
    const subAccEl = this.state.campaigns.find(el => el.id === newCampaign);
    const previousSelectedCampaign = !this.state.selectedCampaign ? newCampaign : this.state.selectedCampaign;
    const selectedCampaignObject = this.state.campaigns.find(el => el.id === newCampaign);

    if (!selectedCampaignObject) {
      console.error('Campaign not found!');
      return;
    }

    const selectedCampaignName = selectedCampaignObject.desc;
    let previousSelectedCampaignName = null;

    if (!this.state.previousSelectedCampaignName) {
      previousSelectedCampaignName = '';
    } else if (this.state.campaigns.find(el => el.id === this.state.selectedCampaign)) {
      previousSelectedCampaignName = this.state.campaigns.find(el => el.id === this.state.selectedCampaign).desc;
    } else {
      previousSelectedCampaignName = this.state.previousSelectedCampaignName;
    }

    if (!this.state.previousSelectedCampaignName && !this.state.selectedCampaignName) {
      previousSelectedCampaignName = selectedCampaignName;
    }
    this.setState({
      selectedCampaign: newCampaign,
      selectedSubAccount: subAccEl.subAcc,
      previousSelectedCampaign: previousSelectedCampaign,
      previousSelectedCampaignName: previousSelectedCampaignName,
      selectedCampaignName: selectedCampaignName,
      selectedCampaignObject: selectedCampaignObject
    });
    const readOptions = {
      account: this.state.selectedAccount,
      subAccount: subAccEl.subAcc,
      campaign: newCampaign,
      campaignName: subAccEl.desc
    };

    if (this.state.platform === PLATFORMS.GOOGLE_ADS) {
      this.setState({ isLoading: true });
      const campName = this.state.campaigns.find(el => el.id === newCampaign).name;
      getGoogleAdsCampaignByName(campName).then(camp => {
        const widgetId = camp.account.campaign.article;
        const widgetData = this.state.articleList.wid[widgetId];
        camp.account.campaign[GLOBAL.KEY_WIDGET_CODE_NAME] =
          typeof widgetData.clv === 'string' ? widgetData.clv.substring(0, 5) : '';
        camp.account.campaign[GLOBAL.KEY_WIDGET_LANGUAGE_CODE] =
          typeof widgetData.clv === 'string' ? widgetData.clv.substring(5, 7) : '';
        this.setState({ sourceData: camp, isLoading: false });
      });
    } else if (this.state.platform === PLATFORMS.BAIDU) {
      this.setState({ isLoading: true });
      const selectedAcc = this.state.accounts.filter(e => e.id === this.state.selectedAccount)[0].desc;
      const readOptions = {
        account: this.state.selectedAccount,
        campaign: newCampaign,
        campaignName: subAccEl.desc
      };
      getBaiduCampaign(readOptions.account, newCampaign).then(camp => {
        this.setState({ sourceData: camp, isLoading: false });
      });
    } else if (this.state.platform === PLATFORMS.TWITTER) {
      if (!this.state.loadedFromSnapshot) {
        this.setState({ isLoading: true }, () => {
          readTwitterCampaign(this.state.selectedAccount, newCampaign).then(async campaign => {
            campaign.campaign.campaignCreator = (await getAdName(campaign.campaign.name))?.creator || '';
            let options = this.state.options;
            const prefix = parseCampaignName(this.state.platform, subAccEl.name).prefix;
            options.prefix = prefix;
            this.setState({ sourceData: campaign, options: options });
            this.processNativeData(campaign);
          });
        });
      }
    } else if (this.state.platform === PLATFORMS.FACEBOOK || this.state.platform === PLATFORMS.FACEBOOK2) {
      this.setState({ isLoading: true });
      fetchCampaigns(
        this.state.ws,
        this.state.session,
        PLATFORMS.FACEBOOK,
        selectedCampaignObject,
        this.state.selectedAccount
      );
    } else if (this.state.platform === PLATFORMS.FACEBOOK_COPY) {
      this.setState({ isLoading: true });
      fetchCampaignsStructure(
        this.state.ws,
        this.state.session,
        PLATFORMS.FACEBOOK,
        newCampaign,
        this.state.selectedAccount
      );
    } else if (this.state.platform === PLATFORMS.TABOOLA) {
      const campName = this.state.campaigns.find(el => el.id === newCampaign).name;
      this.setState({ isLoading: true });
      let payload = {
        echo: COMMANDS.LOAD_CAMPAIGN,
        name: campName
      };
      loadTaboolaCampaign(this.state.ws, payload);
    } else if (this.state.platform === PLATFORMS.OUTBRAIN) {
      const campName = this.state.campaigns.find(el => el.id === newCampaign).name;
      this.setState({ isLoading: true });
      let payload = {
        echo: COMMANDS.LOAD_CAMPAIGN,
        name: campName
      };
      loadOutbrainCampaign(this.state.ws, payload);
    } else if (this.state.platform === PLATFORMS.ZEMANTA) {
      const campName = this.state.campaigns.find(el => el.id === newCampaign).name;
      let payload = { name: campName };
      wsCampaignLoad(this.state.ws, PLATFORMS.ZEMANTA, payload);
      this.setState({ isLoading: true });
    } else {
      startRead(this.state.ws, this.state.session, this.state.platform, readOptions);
    }
  }

  getSnapshotOptions() {
    const queryParams = qs.parse(window.location.search.replace('?', ''));

    return {
      id: queryParams.snapshotId,
      location: `${window.location.origin}${window.location.pathname}`,
      selectedTask: this.state.selectedTask,
      selectedAccount: this.state.selectedAccount,
      selectedCampaign: this.state.selectedCampaign,
      selectedCampaignName: this.state.selectedCampaignName,
      selectedPrefix: this.state.selectedPrefix,
      selectedSubAccount: this.state.selectedSubAccount
    };
  }

  campaignCreate(options) {
    const request = wsCampaignCreate(this.state.ws, this.state.platform, this.props.isDevSkipCreate(), options);
    this.props.trace([
      {
        id: `echo${request.echo}`,
        name: `${this.state.platform} campaign ${request.echo}`,
        request
      }
    ]);
  }

  createCampaign(options) {
    let finalOptions = options || this.state.options;
    if (Array.isArray(finalOptions)) {
      finalOptions.forEach(el => {
        el.userMail = this.state.user;
      });
    } else {
      finalOptions.userMail = this.state.user;
    }
    const request = createCampaign(
      this.state.ws,
      this.state.session,
      null, // For TB, the server determines the correct destination account
      this.state.platform,
      finalOptions,
      this.getSnapshotOptions(),
      this.props.isDevSkipCreate()
    );
    if (this.state.platform === PLATFORMS.FACEBOOK || this.state.platform === PLATFORMS.FACEBOOK2) {
      this.props.trace([
        {
          id: this.state.session,
          name: `${this.state.platform} campaigns ${this.state.session}`,
          request
        }
      ]);
    } else {
      const toastId = toast(<CustomNotification shortText="Creating campaigns" longText="" />, {
        autoClose: false,
        closeOnClick: false
      });
      let sessionData = this.state.sessionData;
      if (!sessionData[this.state.session]) {
        sessionData[this.state.session] = {
          task: TASKS.CREATE
        };
      } else {
        sessionData[this.state.session].task = TASKS.CREATE;
      }
      let progressToastIds = this.state.progressToastIds;
      progressToastIds[this.state.session] = toastId;
      let toastIdProgressPairs = [];
      toastIdProgressPairs.push({ progressId: this.state.session, toastId: toastId });

      this.setState({
        progressToastIds: progressToastIds,
        sessionData: sessionData,
        toastIdProgressPairs
      });
    }
    // Immediately init the next session
    initNewSession(this.state.ws, this.state.platform, this.state.session);
  }

  updatePCamp(options) {
    // console.log('updatePCamp');
    const request = updateCamp(this.state.ws, this.state.session, PLATFORMS.PINTEREST, options);
    console.log(request);
  }

  duplicateCampaign(opts) {
    const options = opts || this.state.options;
    options.sourceAccount = this.state.selectedAccount;
    options.sourceCampaign = this.state.selectedCampaign;
    options.sourceSubAccount = this.state.selectedSubAccount;
    options.userMail = this.state.user;

    duplicateCampaign(
      this.state.ws,
      this.state.session,
      null, // For TB, the server determines the correct destination account
      this.state.platform,
      options,
      null,
      null,
      null,
      null,
      this.getSnapshotOptions(),
      this.props.isDevSkipCreate()
    );
    const toastId = toast(<CustomNotification shortText="Duplicating campaign" longText="" />, {
      autoClose: false,
      closeOnClick: false
    });
    let sessionData = this.state.sessionData;
    if (!sessionData[this.state.session]) {
      sessionData[this.state.session] = {
        task: this.state.selectedTask
      };
    } else {
      sessionData[this.state.session].task = this.state.selectedTask;
    }
    let progressToastIds = this.state.progressToastIds;
    progressToastIds[this.state.session] = toastId;
    this.setState({
      progressToastIds: progressToastIds,
      sessionData: sessionData,
      newAccount: false
    });
    // Immediately init the next session
    initNewSession(this.state.ws, this.state.platform, this.state.session);
  }

  modifyOptions(key, value) {
    const options = this.state.options;
    options[key] = value;
    this.setState({
      options: options
    });
  }

  startLoading() {
    this.setState({
      isLoading: true
    });
  }

  endLoading() {
    this.setState({
      isLoading: false
    });
  }
  fetchTbBulkCampaigns(camps) {
    if (camps && camps.length > 0) {
      const campaigns = camps
        .filter(camp => camp.value)
        .map(camp => camp.label)
        .join(',');
      try {
        this.setState({ sourceCampaigns: campaigns, isLoading: true });
        let payload = {
          echo: COMMANDS.LOAD_BULK_CAMPAIGNS,
          name: campaigns
        };
        loadTaboolaBulk(this.state.ws, payload);
      } catch (e) {
        this.setState({ isLoading: false });
        toast.info(`Invalid campaign format`, { e });
      }
    }
  }
  fetchObBulkCampaigns(camps) {
    if (camps && camps.length > 0) {
      const campaigns = camps
        .filter(camp => camp.value)
        .map(camp => camp.label)
        .join(',');
      try {
        this.setState({ sourceCampaigns: campaigns, isLoading: true });
        let payload = {
          echo: COMMANDS.LOAD_BULK_CAMPAIGNS,
          name: campaigns
        };
        loadOutbrainBulk(this.state.ws, payload);
      } catch (e) {
        this.setState({ isLoading: false });
        toast.info(`Invalid campaign format`, { e });
      }
    }
  }

  renderBaiduCreate() {
    return (
      <BaiduCreate
        state={this.state}
        account={this.state.selectedAccount}
        accounts={this.state.accounts}
        platformSites={this.allPlatformSites.baidu}
        modifyOptions={(key, value) => this.modifyOptions(key, value)}
        countryList={this.countryList}
        articleList={this.state.articleList}
        siteList={this.BdFilteredSiteList}
        task={this.state.selectedTask}
        createCampaign={opts => this.createCampaign(opts)}
        duplicateCampaign={opts => this.duplicateCampaign(opts)}
        sourceData={this.state.sourceData}
        triggerRowUpdate={this.state.triggerRowUpdate}
        triggerSelectorUpdate={this.state.triggerSelectorUpdate}
        ws={this.state.ws}
        session={this.state.session}
        startLoading={() => this.startLoading()}
        creativeFromInsights={this.state.creativeFromInsights}
        creativeFromFbLibrary={this.state.creativeFromFbLibrary}
        creative={this.state.creative}
        creativeDone={() => {
          this.setState({ creative: null });
        }}
        creativeFbLibraryDone={() => {
          this.setState({ creativeFromFbLibrary: null });
        }}
        programOptions={this.state.programOptions}
        options={this.state.loadedFromSnapshot ? this.state.options : {}}
        loadedFromSnapshot={this.state.loadedFromSnapshot}
        upsertSnapshot={opts => this.upsertSnapshot(opts)}
        creatorsList={this.state.creatorsList}
      />
    );
  }

  renderTaboolaCreate() {
    return (
      <TaboolaCreate
        task={this.state.selectedTask}
        platform={this.state.platform}
        account={this.state.selectedAccount}
        campaign={this.state.selectedCampaign}
        countryList={this.countryList}
        articleList={this.state.articleList}
        siteList={this.TbFilteredSiteList}
        campaignCreate={opts => this.campaignCreate(opts)}
        duplicateCampaign={opts => this.duplicateCampaign(opts)}
        sourceData={this.state.sourceData}
        programOptions={this.state.programOptions}
        destinationAccount={this.state.options[GLOBAL.KEY_DESTINATION_ACCOUNT]}
        creativeFromFbLibrary={this.state.creativeFromFbLibrary}
        creative={this.state.creative}
        creativeFbLibraryDone={() => {
          this.setState({ creativeFromFbLibrary: null });
        }}
        creativeDone={() => {
          this.setState({ creative: null });
        }}
        options={this.state.loadedFromSnapshot ? this.state.options : {}}
        loadedFromSnapshot={this.state.loadedFromSnapshot}
        upsertSnapshot={opts => this.upsertSnapshot(opts)}
        creatorsList={this.state.creatorsList}
        selectedSubAccount={this.state.selectedSubAccount}
        subAccounts={this.state.subAccounts}
        fetchTbBulkCampaigns={camps => this.fetchTbBulkCampaigns(camps)}
        isBulk={this.state.isBulk}
      />
    );
  }

  renderFacebookCreate() {
    const sourceData = this.state.sourceData ? this.state.sourceData[0] : null;
    return (
      <FacebookCreate
        task={this.state.selectedTask}
        platform={this.state.platform}
        account={this.state.selectedAccount}
        campaign={this.state.selectedCampaign}
        prefix={this.getCampaignDescriptionPrefix(this.state.selectedCampaignName)}
        countryList={this.countryList}
        articleList={this.state.articleList}
        siteList={this.FbFilteredSiteList}
        notFilteredSiteList={this.notFilteredSiteList}
        createCampaign={opts => this.createCampaign(opts)}
        duplicateCampaign={opts => this.duplicateCampaign(opts)}
        programOptions={this.state.programOptions}
        destinationAccount={this.state.options[GLOBAL.KEY_DESTINATION_ACCOUNT]}
        accounts={this.state.accounts}
        creativeFromFbLibrary={this.state.creativeFromFbLibrary}
        creative={this.state.creative}
        creativeFbLibraryDone={() => {
          this.setState({ creativeFromFbLibrary: null });
        }}
        creativeDone={() => {
          this.setState({ creative: null });
        }}
        options={this.state.loadedFromSnapshot ? this.state.options : {}}
        loadedFromSnapshot={this.state.loadedFromSnapshot}
        upsertSnapshot={opts => this.upsertSnapshot(opts)}
        sourceData={sourceData}
        campaignCreator={this.state.campaignCreator}
        campaignCreatorDone={() => {
          this.setState({ campaignCreator: null });
        }}
        spmCreativeBankAccess={this.state.spmCreativeBankAccess}
        spmUploadAccess={this.state.spmUploadAccess}
        spmReviewAccess={this.state.spmReviewAccess}
        isCreativesReviewer={this.state.isCreativesReviewer}
        creatorsList={this.state.creatorsList}
        userMail={this.state.user}
        startLoading={() => this.startLoading()}
        endLoading={() => this.endLoading()}
      />
    );
  }
  renderFacebookCreate2() {
    return (
      <FacebookCreate2
        task={this.state.selectedTask}
        platform={this.state.platform}
        account={this.state.selectedAccount}
        campaign={this.state.selectedCampaign}
        prefix={this.getCampaignDescriptionPrefix(this.state.selectedCampaignName)}
        countryList={this.countryList}
        articleList={this.state.articleList}
        siteList={this.FbFilteredSiteList}
        notFilteredSiteList={this.notFilteredSiteList}
        createCampaign={opts => this.createCampaign(opts)}
        duplicateCampaign={opts => this.duplicateCampaign(opts)}
        programOptions={this.state.programOptions}
        destinationAccount={this.state.options[GLOBAL.KEY_DESTINATION_ACCOUNT]}
        accounts={this.state.accounts}
        creativeFromFbLibrary={this.state.creativeFromFbLibrary}
        creative={this.state.creative}
        creativeFbLibraryDone={() => {
          this.setState({ creativeFromFbLibrary: null });
        }}
        creativeDone={() => {
          this.setState({ creative: null });
        }}
        options={this.state.loadedFromSnapshot ? this.state.options : {}}
        loadedFromSnapshot={this.state.loadedFromSnapshot}
        upsertSnapshot={opts => this.upsertSnapshot(opts)}
        sourceData={this.state.sourceData}
        sourceCampaigns={this.state.sourceCampaigns}
        updateSourceCampaigns={camps => this.updateSourceCampaigns(camps)}
        handleCampaignsPaste={e => this.handleCampaignsPaste(e)}
        handleBulkKeyDown={e => this.handleBulkKeyDown(e)}
        creativeFromInsights={this.state.creativeFromInsights}
        handleCreativesPaste={e => this.handleCreativesPaste(e)}
        creativeHashes={this.state.creativeHashes}
        updateCreativeHashes={hashes => this.updateCreativeHashes(hashes)}
        handleCreativeHashesKeyDown={e => this.handleCreativeHashesKeyDown(e)}
        creatorsList={this.state.creatorsList}
        userMail={this.state.user}
        allowPageSelection={this.props.allowPageSelection}
        startLoading={() => this.startLoading()}
        endLoading={() => this.endLoading()}
        fetchFbBulkCampaigns={camps => this.fetchFbBulkCampaigns(camps)}
        blacklistedCreatives={this.state.blacklistedCreatives}
      />
    );
  }

  renderFacebookReviewCreatives() {
    return (
      <FacebookReviewCreatives
        isCreativesReviewer={this.state.isCreativesReviewer}
        articleList={this.state.articleList}
        programOptions={this.state.programOptions}
      />
    );
  }

  renderFacebookCopy() {
    return (
      <FacebookCopy
        sourceData={this.state.sourceData}
        articleList={this.state.articleList}
        ws={this.state.ws}
        session={this.state.session}
        creatorsList={this.state.creatorsList}
        programOptions={this.state.programOptions}
        trace={this.props.trace}
        isDevSkipCreate={this.props.isDevSkipCreate}
      />
    );
  }

  renderFacebookBulk() {
    return (
      <FacebookBulk
        countryList={this.countryList}
        articleList={this.state.articleList}
        siteList={this.FbFilteredSiteList}
        notFilteredSiteList={this.notFilteredSiteList}
        createCampaign={this.createCampaign.bind(this)}
        sourceData={this.state.sourceData}
        programOptions={this.state.programOptions}
        destinationAccount={this.state.options[GLOBAL.KEY_DESTINATION_ACCOUNT]}
        accounts={this.state.accounts}
        options={this.state.loadedFromSnapshot ? this.state.options : {}}
        loadedFromSnapshot={this.state.loadedFromSnapshot}
        upsertSnapshot={this.upsertSnapshot.bind(this)}
        userMail={this.state.user}
        creatorsList={this.state.creatorsList}
      />
    );
  }

  renderFacebookUploadCreatives() {
    return (
      <FacebookUploadCreatives
        task={TASKS.CREATE}
        platform={this.state.platform}
        programOptions={this.state.programOptions}
        articleList={this.state.articleList}
      />
    );
  }

  renderTwitterBulk() {
    return (
      <TwitterBulk
        state={this.state}
        modifyOptions={(key, value) => this.modifyOptions(key, value)}
        countryList={this.countryList}
        articleList={this.state.articleList}
        siteList={this.TwFilteredSiteList}
        task={this.state.selectedTask}
        createCampaign={opts => this.createCampaign(opts)}
        sourceData={this.state.sourceData}
        ws={this.state.ws}
        startLoading={() => this.startLoading()}
        endLoading={() => this.endLoading()}
        options={this.state.loadedFromSnapshot ? this.state.options : {}}
        programOptions={this.state.programOptions}
        userMail={this.state.user}
        sourceCampaigns={this.state.sourceCampaigns}
        updateSourceCampaigns={camps => this.updateSourceCampaigns(camps)}
        handleCampaignsPaste={e => this.handleCampaignsPaste(e)}
        handleBulkKeyDown={e => this.handleBulkKeyDown(e)}
        creativeFromInsights={this.state.creativeFromInsights}
        handleCreativesPaste={e => this.handleCreativesPaste(e)}
        creativeHashes={this.state.creativeHashes}
        updateCreativeHashes={hashes => this.updateCreativeHashes(hashes)}
        handleCreativeHashesKeyDown={e => this.handleCreativeHashesKeyDown(e)}
        session={this.state.session}
        creatorsList={this.state.creatorsList}
      />
    );
  }

  renderZemantaCreate() {
    return (
      <ZemantaCreate
        task={this.state.selectedTask}
        platform={this.state.platform}
        account={this.state.selectedAccount}
        accounts={this.state.accounts}
        campaign={this.state.selectedCampaign}
        countryList={this.countryList}
        articleList={this.state.articleList}
        createCampaign={opts => this.campaignCreate(opts)}
        duplicateCampaign={opts => this.duplicateCampaign(opts)}
        siteList={this.ZmFilteredSiteList}
        sourceData={this.state.sourceData}
        programOptions={this.state.programOptions}
        destinationAccount={this.state.options[GLOBAL.KEY_DESTINATION_ACCOUNT]}
        interestsList={this.state.OBinterestsList}
        osChoices={this.state.options[ZM.KEY_OS_CHOICES]}
        creativeFromFbLibrary={this.state.creativeFromFbLibrary}
        creative={this.state.creative}
        creativeDone={() => {
          this.setState({ creative: null });
        }}
        creativeFbLibraryDone={() => {
          this.setState({ creativeFromFbLibrary: null });
        }}
        options={this.state.loadedFromSnapshot ? this.state.options : {}}
        loadedFromSnapshot={this.state.loadedFromSnapshot}
        upsertSnapshot={opts => this.upsertSnapshot(opts)}
        subAccounts={this.state.subAccounts}
        creatorsList={this.state.creatorsList}
        selectedSubAccount={this.state.selectedSubAccount}
        fetchObBulkCampaigns={camps => this.fetchObBulkCampaigns(camps)}
        isBulk={this.state.isBulk}
      />
    );
  }

  renderPinterestCreate() {
    return (
      <PinterestCreate
        task={this.state.selectedTask}
        platform={this.state.platform}
        account={this.state.selectedAccount}
        accounts={this.state.accounts}
        campaign={this.state.selectedCampaign}
        countryList={this.countryList}
        articleList={this.state.articleList}
        createCampaign={opts => this.campaignCreate(opts)}
        duplicateCampaign={opts => this.duplicateCampaign(opts)}
        siteList={this.PnFilteredSiteList}
        sourceData={this.state.sourceData}
        programOptions={this.state.programOptions}
        destinationAccount={this.state.options[GLOBAL.KEY_DESTINATION_ACCOUNT]}
        osChoices={this.state.options[PN.KEY_OS_CHOICES]}
        creativeFromFbLibrary={this.state.creativeFromFbLibrary}
        creative={this.state.creative}
        creativeDone={() => {
          this.setState({ creative: null });
        }}
        creativeFbLibraryDone={() => {
          this.setState({ creativeFromFbLibrary: null });
        }}
        options={this.state.loadedFromSnapshot ? this.state.options : {}}
        loadedFromSnapshot={this.state.loadedFromSnapshot}
        upsertSnapshot={opts => this.upsertSnapshot(opts)}
        subAccounts={this.state.subAccounts}
        creatorsList={this.state.creatorsList}
        selectedSubAccount={this.state.selectedSubAccount}
        fetchObBulkCampaigns={camps => this.fetchObBulkCampaigns(camps)}
        isBulk={this.state.isBulk}
        updatePCamp={o => this.updatePCamp(o)}
      />
    );
  }
  renderPlatformSpecific(platform) {
    switch (platform) {
      case PLATFORMS.BAIDU:
        return this.renderBaiduCreate();
      case PLATFORMS.TABOOLA:
        return this.renderTaboolaCreate();
      case PLATFORMS.FACEBOOK:
        return this.renderFacebookCreate();
      case PLATFORMS.FACEBOOK2:
        return this.renderFacebookCreate2();
      case PLATFORMS.FACEBOOK_REVIEW_CREATIVES:
        return this.renderFacebookReviewCreatives();
      case PLATFORMS.FACEBOOK_COPY:
        return this.renderFacebookCopy();
      case PLATFORMS.FACEBOOK_BULK:
        return this.renderFacebookBulk();
      case PLATFORMS.ZEMANTA:
        return this.renderZemantaCreate();
      case PLATFORMS.REVCONTENT:
        return (
          <RevcontentCreate
            state={this.state}
            modifyOptions={(key, value) => this.modifyOptions(key, value)}
            countryList={this.countryList}
            articleList={this.state.articleList}
            siteList={this.RcFilteredSiteList}
            task={this.state.selectedTask}
            createCampaign={opts => this.createCampaign(opts)}
            duplicateCampaign={opts => this.duplicateCampaign(opts)}
            sourceData={this.state.sourceData}
            triggerRowUpdate={this.state.triggerRowUpdate}
            programOptions={this.state.programOptions}
            triggerSelectorUpdate={this.state.triggerSelectorUpdate}
            creativeFromFbLibrary={this.state.creativeFromFbLibrary}
            creative={this.state.creative}
            creativeDone={() => {
              this.setState({ creative: null });
            }}
            creativeFbLibraryDone={() => {
              this.setState({ creativeFromFbLibrary: null });
            }}
            options={this.state.loadedFromSnapshot ? this.state.options : {}}
            loadedFromSnapshot={this.state.loadedFromSnapshot}
            upsertSnapshot={opts => this.upsertSnapshot(opts)}
          />
        );
      case PLATFORMS.OUTBRAIN:
        return (
          <OutbrainCreate
            task={this.state.selectedTask}
            platform={this.state.platform}
            account={this.state.selectedAccount}
            campaign={this.state.selectedCampaign}
            countryList={this.countryList}
            articleList={this.state.articleList}
            createCampaign={opts => this.campaignCreate(opts)}
            duplicateCampaign={opts => this.duplicateCampaign(opts)}
            siteList={this.ObFilteredSiteList}
            sourceData={this.state.sourceData}
            programOptions={this.state.programOptions}
            destinationAccount={this.state.options[GLOBAL.KEY_DESTINATION_ACCOUNT]}
            interestsList={this.state.OBinterestsList}
            osChoices={this.state.options[OB.KEY_OS_CHOICES]}
            creativeFromFbLibrary={this.state.creativeFromFbLibrary}
            creative={this.state.creative}
            creativeDone={() => {
              this.setState({ creative: null });
            }}
            creativeFbLibraryDone={() => {
              this.setState({ creativeFromFbLibrary: null });
            }}
            options={this.state.loadedFromSnapshot ? this.state.options : {}}
            loadedFromSnapshot={this.state.loadedFromSnapshot}
            upsertSnapshot={opts => this.upsertSnapshot(opts)}
            accounts={this.state.accounts}
            creatorsList={this.state.creatorsList}
            selectedSubAccount={this.state.selectedSubAccount}
            fetchObBulkCampaigns={camps => this.fetchObBulkCampaigns(camps)}
            isBulk={this.state.isBulk}
          />
        );
      case PLATFORMS.TWITTER:
        if (this.state.twitterReady) {
          return (
            <TwitterCreate
              state={this.state}
              modifyOptions={(key, value) => this.modifyOptions(key, value)}
              countryList={this.countryList}
              articleList={this.state.articleList}
              siteList={this.TwFilteredSiteList}
              task={this.state.selectedTask}
              createCampaign={opts => this.createCampaign(opts)}
              duplicateCampaign={opts => this.duplicateCampaign(opts)}
              sourceData={this.state.sourceData}
              triggerRowUpdate={this.state.triggerRowUpdate}
              triggerSelectorUpdate={this.state.triggerSelectorUpdate}
              ws={this.state.ws}
              session={this.state.session}
              startLoading={() => this.startLoading()}
              creative={this.state.creative}
              creativeDone={() => {
                this.setState({ creative: null });
              }}
              creativeFromInsights={this.state.creativeFromInsights}
              creativeFromFbLibrary={this.state.creativeFromFbLibrary}
              options={this.state.loadedFromSnapshot ? this.state.options : {}}
              loadedFromSnapshot={this.state.loadedFromSnapshot}
              upsertSnapshot={opts => this.upsertSnapshot(opts)}
              programOptions={this.state.programOptions}
              userMail={this.state.user}
              creatorsList={this.state.creatorsList}
            />
          );
        } else {
          return null;
        }
      case PLATFORMS.TWITTER_BULK:
        return this.renderTwitterBulk();
      case PLATFORMS.FACEBOOK_UPLOAD_CREATIVES:
        return this.renderFacebookUploadCreatives();
      case PLATFORMS.GOOGLE_ADS:
        return (
          <GoogleAdsCreate
            task={this.state.selectedTask}
            platform={this.state.platform}
            account={this.state.selectedAccount}
            campaign={this.state.selectedCampaign}
            accounts={this.state.accounts}
            destinationAccount={this.state.options[GLOBAL.KEY_DESTINATION_ACCOUNT]}
            modifyOptions={(key, value) => this.modifyOptions(key, value)}
            countryList={this.countryList}
            articleList={this.state.articleList}
            siteList={this.GaFilteredSiteList}
            createGoogleAdsCampaign={opts => this.campaignCreate(opts)}
            duplicateCampaign={opts => this.duplicateCampaign(opts)}
            sourceData={this.state.sourceData}
            triggerRowUpdate={this.state.triggerRowUpdate}
            triggerSelectorUpdate={this.state.triggerSelectorUpdate}
            ws={this.state.ws}
            session={this.state.session}
            startLoading={() => this.startLoading()}
            creativeFromInsights={this.state.creativeFromInsights}
            creativeFromFbLibrary={this.state.creativeFromFbLibrary}
            creative={this.state.creative}
            creativeDone={() => {
              this.setState({ creative: null });
            }}
            creativeFbLibraryDone={() => {
              this.setState({ creativeFromFbLibrary: null });
            }}
            programOptions={this.state.programOptions}
            options={this.state.loadedFromSnapshot ? this.state.options : {}}
            loadedFromSnapshot={this.state.loadedFromSnapshot}
            upsertSnapshot={opts => this.upsertSnapshot(opts)}
            creatorsList={this.state.creatorsList}
          />
        );
      case PLATFORMS.PINTEREST:
        return this.renderPinterestCreate();
      default:
        return null;
    }
  }

  getTasks() {
    let tasks = { ...TASKS };
    if (this.state.platform === PLATFORMS.TABOOLA) {
      tasks[TB.DUPLICATE_WITH_DATA_SEGMENTS] = TB.DUPLICATE_WITH_DATA_SEGMENTS;
    }
    if (this.state.user === REVIEWER.DEMO_WW) {
      tasks = { CREATE: 'Create' };
    }
    return Object.values(tasks).map(el => ({ label: el, value: el }));
  }

  isDuplicate() {
    let result =
      this.state.selectedTask === TASKS.DUPLICATE || this.state.selectedTask === TB.DUPLICATE_WITH_DATA_SEGMENTS;
    return result;
  }

  getPlatforms() {
    if (this.state.programOptions && this.state.programOptions.spm_access) {
      return PLATFORM_OPTIONS.filter(x => this.state.programOptions.platforms.includes(x.value));
    } else {
      return [];
    }
  }

  getPlatformsItems() {
    if (this.state.spmUploadAccess && !this.state.spmReviewAccess) {
      return this.getPlatforms().filter(e => e.value !== PLATFORMS.FACEBOOK_REVIEW_CREATIVES);
    } else if (this.state.spmReviewAccess && !this.state.spmUploadAccess) {
      return this.getPlatforms().filter(e => e.value !== PLATFORMS.FACEBOOK_UPLOAD_CREATIVES);
    } else if (!this.state.spmUploadAccess && !this.state.spmReviewAccess) {
      return this.getPlatforms().filter(
        e => e.value !== PLATFORMS.FACEBOOK_REVIEW_CREATIVES && e.value !== PLATFORMS.FACEBOOK_UPLOAD_CREATIVES
      );
    } else {
      return this.getPlatforms();
    }
  }

  rejectReview() {
    const queryParams = qs.parse(window.location.search.replace('?', ''));

    rejectReview(queryParams.snapshotId, this.rejectCampaignReasonInput.value).then(({ success }) => {
      if (success) {
        toast.success(`Campaign rejected`, {
          autoClose: false
        });
      } else {
        toast.error(`Campaign does not exist`, {
          autoClose: false
        });
      }
    });
  }

  upsertSnapshot(options) {
    upsertSnapshot(
      this.state.ws,
      this.state.session,
      this.state.options.destinationAccount, // For TB, the server determines the correct destination account
      this.state.platform,
      options || this.state.options,
      this.getSnapshotOptions()
    );

    const toastId = toast(<CustomNotification shortText="Creating snapshot" longText="" />);

    let sessionData = this.state.sessionData;
    if (!sessionData[this.state.session]) {
      sessionData[this.state.session] = {
        task: TASKS.CREATE
      };
    } else {
      sessionData[this.state.session].task = TASKS.CREATE;
    }
    let progressToastIds = this.state.progressToastIds;
    progressToastIds[this.state.session] = toastId;
    this.setState({
      progressToastIds: progressToastIds,
      sessionData: sessionData
    });
    // Immediately init the next session
    initNewSession(this.state.ws, this.state.platform, this.state.session);
  }

  handleCampaignsPaste(e) {
    //ua-us-m-edm-lamomen-ukfih8-c325, ua-us-m-edm-lamomen-ukfih8-c324
    //u0-us-m-edm-7090sen-ukedm9-c85-a1-v1,xu-us-m-fih-backeen-p2fih4-c21-a1-v5
    let campaigns = '';
    if (e.clipboardData) {
      e.preventDefault();
      campaigns = e.clipboardData.getData('Text');
    } else {
      campaigns = e;
    }
    if (campaigns.length) {
      campaigns = campaigns.replace(/,*$/, '');
    }
    this.setState({ isLoading: true, sourceCampaigns: campaigns });
    campaigns = campaigns.split(',').map(el => el.trim());
    fetchBulkCampaigns(this.state.ws, this.state.session, campaigns, PLATFORMS.FACEBOOK, this.state.selectedAccount);
  }

  fetchFbBulkCampaigns(camps) {
    if (camps && camps.length > 0) {
      const campaigns = camps.filter(camp => camp.value).map(camp => camp.label);
      console.log({ campaigns });
      try {
        this.setState({ sourceCampaigns: campaigns, isLoading: true });
        fetchBulkCampaigns(
          this.state.ws,
          this.state.session,
          campaigns,
          PLATFORMS.FACEBOOK,
          this.state.selectedAccount
        );
      } catch (e) {
        this.setState({ isLoading: false });
        toast.info(`Invalid campaign format`, { e });
      }
    }
  }

  handleCreativesPaste(e) {
    //3a12b33c310f21d5c17350ca3f359c45ca20934a,0e34fa967d135d85e6bdb80aaea2e2699168b8c9
    let creativeHashes = '';
    if (e.clipboardData) {
      e.preventDefault();
      creativeHashes = e.clipboardData.getData('Text');
    } else {
      creativeHashes = e;
    }
    this.setState({ creativeHashes, isLoading: true });
    this.setCreativesByIds(creativeHashes);
  }

  updateCreativeHashes(creativeHashes) {
    this.setState({ creativeHashes });
  }

  handleCreativeHashesKeyDown(e) {
    if (e.key === 'Enter') {
      let creativeHashes = e.target.value;
      this.setState({ creativeHashes });
      this.setCreativesByIds(creativeHashes);
    }
  }

  updateSourceCampaigns(sourceCampaigns) {
    this.setState({ sourceCampaigns });
  }
  handleBulkKeyDown(e) {
    if (e.key === 'Enter') {
      let campaigns = e.target.value;
      if (!e.target.value) {
        this.setState({ isLoading: false, sourceData: '' });
        return;
      }
      this.setState({ isLoading: true, sourceCampaigns: e.target.value });
      campaigns = campaigns.split(',').map(el => el.trim());

      fetchBulkCampaigns(this.state.ws, this.state.session, campaigns, PLATFORMS.FACEBOOK, this.state.selectedAccount);
    }
  }

  getAccountOptions(xs) {
    const isFacebookPlatform = GLOBAL.FACEBOOK_PLATFORMS.includes(this.state.platform);

    return xs
      ? xs
          .map(el => {
            return {
              label: el.desc || el.name,
              value: el.id || el.account_id,
              ...(isFacebookPlatform && {
                isOptionDisabled:
                  el.fb_account_status !== undefined && el.fb_account_status !== null && el.fb_account_status !== 1
              })
            };
          })
          .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
      : [];
  }

  getSubAccountOptions(xs) {
    return xs
      ? Object.values(xs)
          .map(el => {
            return { label: el.name || el.account_id, value: el.account_id || el.account };
          })
          .filter(el => !TB.OLD_ACCOUNTS.includes(el.value))
          .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
      : [];
  }

  handleChangeCampaignNames(selectedOptions) {
    const isBulk = selectedOptions && selectedOptions.length > 0;
    this.setState({ selectedCampaigns: selectedOptions, isBulk });
  }

  handleCopyChange(site, newValue) {
    this.setState(prevState => {
      const newSiteCopies = { ...prevState.options.siteCopies, [site]: newValue };
      return { options: { ...prevState.options, siteCopies: newSiteCopies } };
    });
  }

  fetchCampaigns(camps) {
    if (camps && camps.length > 0) {
      const names = camps.filter(camp => camp.value).map(camp => camp.label);
      try {
        this.setState({ sourceCampaigns: names, isLoading: true });
        if (this.state.platform === PLATFORMS.OUTBRAIN) {
          loadOutbrainBulk(this.state.ws, {
            name: names
          });
        } else if (this.state.platform === PLATFORMS.TABOOLA) {
          loadTaboolaBulk(this.state.ws, {
            name: names
          });
        } else {
          fetchBulkCampaigns(this.state.ws, this.state.session, names);
        }
      } catch (e) {
        this.setState({ isLoading: false });
        toast.info(`Invalid campaign format`, { e });
      }
    }
  }

  handleCreateOption(inputValue) {
    const { campaignOptions } = this.state;
    const normalizedInput = inputValue.replace(/\s+/g, ' ');
    const inputCampaigns = normalizedInput.split(/[, ]+/);
    const validCampaigns = inputCampaigns
      .filter(value => value.length >= 20 && value.length <= 40)
      .map(name => ({
        value: name,
        label: name
      }));
    const isBulk = validCampaigns && validCampaigns.length > 0;
    this.setState({
      isBulk,
      campaignOptions: [...campaignOptions, ...validCampaigns],
      selectedCampaigns: [...this.state.selectedCampaigns, ...validCampaigns]
    });
  }

  render() {
    const isTaboolaPlatform = this.state.platform === PLATFORMS.TABOOLA;
    const isDuplicate = this.isDuplicate();
    const hasSelectedAccount = isTaboolaPlatform ? this.state.selectedSubAccount : this.state.selectedAccount;
    const { campaignOptions, selectedCampaigns } = this.state;

    if (
      this.state.platform === PLATFORMS.FACEBOOK2 ||
      this.state.platform === PLATFORMS.FACEBOOK_UPLOAD_CREATIVES ||
      this.state.platform === PLATFORMS.FACEBOOK_REVIEW_CREATIVES
    ) {
      return (
        <>
          {(this.state.spmUploadAccess || this.state.spmReviewAccess) &&
          (this.state.platform === PLATFORMS.FACEBOOK_UPLOAD_CREATIVES ||
            this.state.platform === PLATFORMS.FACEBOOK_REVIEW_CREATIVES) ? (
            <CreativeNav
              spmUploadAccess={this.state.spmUploadAccess}
              spmReviewAccess={this.state.spmReviewAccess}
              selectPlatform={pl => this.selectPlatform(pl)}
              platform={this.state.platform}
            />
          ) : null}
          <Grid container direction="row">
            <StyledFbPaper elevation={0} square variant="outlined">
              <Grid container direction="row" spacing={1} alignItems="flex-end">
                {this.state.platform === PLATFORMS.FACEBOOK_UPLOAD_CREATIVES ||
                this.state.platform === PLATFORMS.FACEBOOK_REVIEW_CREATIVES ? null : (
                  <>
                    <Grid item sm={1}>
                      <Typography gutterBottom variant={'subtitle2'}>
                        Platform
                      </Typography>
                      <Select
                        onChange={x => this.selectPlatform(x.value)}
                        options={this.getPlatformsItems()}
                        value={getSelectValue(this.getPlatformsItems(), this.state.platform)}
                      />
                    </Grid>
                  </>
                )}
                {this.state.platform !== PLATFORMS.FACEBOOK_COPY &&
                this.state.platform !== PLATFORMS.FACEBOOK_BULK &&
                this.state.platform !== PLATFORMS.FACEBOOK_UPLOAD_CREATIVES &&
                this.state.platform !== PLATFORMS.FACEBOOK_REVIEW_CREATIVES ? (
                  <Grid item sm={1}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Task
                    </Typography>
                    <Select
                      onChange={x => this.selectTask(x.value)}
                      options={this.getTasks()}
                      value={getSelectValue(this.getTasks(), this.state.selectedTask)}
                    />
                  </Grid>
                ) : null}

                {this.state.platform && this.isDuplicate() && this.state.platform !== PLATFORMS.FACEBOOK_BULK ? (
                  <Grid item sm={2}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Select account
                    </Typography>
                    <Select
                      onChange={e => this.selectAccount(e?.value)}
                      options={this.getAccountOptions(this.state.accounts)}
                      isOptionDisabled={option => option.isOptionDisabled}
                      value={getSelectValue(this.getAccountOptions(this.state.accounts), this.state.selectedAccount)}
                    />
                  </Grid>
                ) : null}
                {this.state.selectedAccount && this.isDuplicate() ? (
                  <Grid item sm={2}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Select prefix campaign
                    </Typography>
                    <Select
                      onChange={prefix => this.selectPrefix(prefix.value)}
                      options={this.state.campaignPrefixes
                        .map(el => ({ label: el, value: el }))
                        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))}
                      value={{ label: this.state.selectedPrefix, value: this.state.selectedPrefix }}
                    />
                  </Grid>
                ) : null}
                {this.state.selectedAccount && this.isDuplicate() ? (
                  <Grid item sm={2}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Select campaign
                    </Typography>
                    <Select
                      onChange={x => this.selectCampaign(x.value)}
                      options={this.state.filteredCampaigns.map(el => {
                        return { label: el.desc, value: el.id };
                      })}
                      value={getSelectValue(
                        this.state.filteredCampaigns.map(el => {
                          return { label: el.desc, value: el.id };
                        }),
                        this.state.selectedCampaign
                      )}
                    />
                  </Grid>
                ) : null}
                {(this.state.selectedCampaign || !this.isDuplicate()) && (
                  <>{this.renderPlatformSpecific(this.state.platform)}</>
                )}
                <Typography gutterBottom variant={'subtitle2'}>
                  {this.state.status}
                </Typography>
              </Grid>
              <div className={this.state.isLoading ? 'placeholder-wrapper isActive' : 'placeholder-wrapper'}>
                <Loader />
              </div>
              <div>
                {this.state.loadedFromSnapshot && (
                  <div>
                    <input
                      type="text"
                      name="rejectCampaignReason"
                      placeholder="Reject review reason"
                      ref={input => {
                        this.rejectCampaignReasonInput = input;
                      }}
                    />
                    <br />
                    <Button variant="contained" color="primary" onClick={() => this.rejectReview()}>
                      Reject campaign
                    </Button>
                  </div>
                )}
              </div>
            </StyledFbPaper>
          </Grid>
        </>
      );
    } else
      return (
        <React.Fragment>
          {this.state.platform === PLATFORMS.FACEBOOK && (this.state.spmUploadAccess || this.state.spmReviewAccess) ? (
            <CreativeNav
              spmUploadAccess={this.state.spmUploadAccess}
              spmReviewAccess={this.state.spmReviewAccess}
              selectPlatform={pl => this.selectPlatform(pl)}
              platform={this.state.platform}
            />
          ) : null}
          <StyledPaper>
            <Grid container direction="row" spacing={1} alignItems={'center'}>
              {this.state.platform !== PLATFORMS.FACEBOOK_UPLOAD_CREATIVES &&
              this.state.platform !== PLATFORMS.FACEBOOK_REVIEW_CREATIVES ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Select platform
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Select
                      onChange={x => this.selectPlatform(x.value)}
                      options={this.getPlatformsItems()}
                      value={getSelectValue(this.getPlatformsItems(), this.state.platform)}
                    />
                  </Grid>
                </>
              ) : null}
              {this.state.platform !== PLATFORMS.FACEBOOK_COPY &&
              this.state.platform !== PLATFORMS.FACEBOOK_BULK &&
              this.state.platform !== PLATFORMS.FACEBOOK_UPLOAD_CREATIVES &&
              this.state.platform !== PLATFORMS.TWITTER_BULK ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Select task
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Select
                      onChange={x => this.selectTask(x.value)}
                      options={this.getTasks()}
                      value={getSelectValue(this.getTasks(), this.state.selectedTask)}
                    />
                  </Grid>
                </>
              ) : null}
              {this.state.selectedTask === TASKS.CREATE &&
                (this.state.platform === PLATFORMS.OUTBRAIN || this.state.platform === PLATFORMS.TABOOLA) && (
                  <BulkSection
                    fetchCampaigns={camps => this.fetchCampaigns(camps)}
                    getSourceCampaigns={camps => this.getSourceCampaigns(camps)}
                    handleChangeCampaignNames={names => this.handleChangeCampaignNames(names)}
                    selectedCampaigns={selectedCampaigns}
                    campaignOptions={campaignOptions}
                    handleCreateOption={e => this.handleCreateOption(e)}
                    sourceData={this.state.sourceData}
                    platform={this.state.platform}
                  />
                )}
              {this.state.platform &&
              this.isDuplicate() &&
              this.state.platform !== PLATFORMS.FACEBOOK_BULK &&
              this.state.platform !== PLATFORMS.TABOOLA ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Select account
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Select
                      onChange={x => this.selectAccount(x.value)}
                      options={this.getAccountOptions(this.state.accounts)}
                      isOptionDisabled={option => option.isOptionDisabled}
                      value={getSelectValue(this.getAccountOptions(this.state.accounts), this.state.selectedAccount)}
                    />
                  </Grid>
                </>
              ) : null}
              {this.state.platform === PLATFORMS.TABOOLA && this.isDuplicate() ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Select subAccount
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Select
                      onChange={x => this.selectSubAccount(x.value)}
                      options={this.getSubAccountOptions(this.state.subAccounts)}
                      value={getSelectValue(
                        this.getSubAccountOptions(this.state.subAccounts),
                        this.state.selectedSubAccount
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              {this.state.platform === PLATFORMS.FACEBOOK_BULK || this.state.platform === PLATFORMS.TWITTER_BULK ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Source campaigns
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <input
                      type="text"
                      name="sourceCampaigns"
                      value={this.state.sourceCampaigns}
                      onChange={e => this.updateSourceCampaigns(e.target.value)}
                      onPaste={this.handleCampaignsPaste.bind(this)}
                      onKeyDown={e => this.handleBulkKeyDown(e)}
                    />
                  </Grid>
                </>
              ) : null}
              {isDuplicate && hasSelectedAccount ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Select prefix campaign
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Select
                      onChange={prefix => this.selectPrefix(prefix.value)}
                      options={this.state.campaignPrefixes
                        .map(el => ({ label: el, value: el }))
                        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))}
                      value={{ label: this.state.selectedPrefix, value: this.state.selectedPrefix }}
                    />
                  </Grid>
                </>
              ) : null}
              {isDuplicate && hasSelectedAccount ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Select campaign
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Select
                      onChange={campaign => this.selectCampaign(campaign.value)}
                      options={this.state.filteredCampaigns.map(el => {
                        return { label: el.desc, value: el.id };
                      })}
                      value={getSelectValue(
                        this.state.filteredCampaigns.map(el => ({ label: el.desc, value: el.id })),
                        this.state.selectedCampaign
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              {(this.state.selectedCampaign || !this.isDuplicate()) && (
                <>{this.renderPlatformSpecific(this.state.platform)}</>
              )}
              <Grid item xs={12} sm={12}>
                <Typography gutterBottom variant={'subtitle2'}>
                  {this.state.status}
                </Typography>
              </Grid>
              <div className={this.state.isLoading ? 'placeholder-wrapper isActive' : 'placeholder-wrapper'}>
                <Loader />
              </div>
              <div>
                {this.state.loadedFromSnapshot && (
                  <div>
                    <input
                      type="text"
                      name="rejectCampaignReason"
                      placeholder="Reject review reason"
                      ref={input => {
                        this.rejectCampaignReasonInput = input;
                      }}
                    />
                    <br />
                    <Button variant="contained" color="primary" onClick={() => this.rejectReview()}>
                      Reject campaign
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
          </StyledPaper>
        </React.Fragment>
      );
  }
}

export default NativeCreate;
