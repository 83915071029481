import { TW, TB, RC, OB, FB, BD, GA, ZM, PN } from '../Constants';
import { toast } from 'react-toastify';
import SHA256 from 'crypto-js/sha256';
import * as Sentry from '@sentry/react';

export class ImageLoadError extends Error {
  constructor(message, event) {
    super(message);
    this.name = 'ImageLoadError';
    this.event = event;
  }
}

const DEFAULT_ALLOWED_IMAGE_FILETYPES = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'];
const platforms = {
  TW: TW,
  TB: TB,
  RC: RC,
  OB: OB,
  FB: FB,
  BD: BD,
  GA: GA,
  ZM: ZM,
  PN: PN
};

export const getImageDimensions = file => {
  return new Promise(function(resolve, reject) {
    let i = new Image();
    i.onload = () => {
      resolve({
        w: i.naturalWidth,
        h: i.naturalHeight
      });
    };
    i.onerror = event => {
      const err = new ImageLoadError('Failed to load image', event);
      Sentry.captureException(err);
      reject(err);
    };
    i.src = URL.createObjectURL(file);
  });
};

export const getVideoDimensions = file => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');

    video.onloadedmetadata = () => {
      resolve({
        w: video.videoWidth,
        h: video.videoHeight
      });
    };

    video.onerror = event => {
      const err = new Error('Failed to load video', event);
      Sentry.captureException(err);
      reject(err);
    };

    video.src = URL.createObjectURL(file);
  });
};

/**
 * @param  {string} fname
 * @param {string} type
 */
export function truncateFileName(fname, type) {
  let name = fname.split('.');
  if (name[1] && name[1] === 'jpg') {
    let filename_truncated = fname;
    if (filename_truncated.length > 30) {
      filename_truncated =
        filename_truncated.substr(0, 12) + '...' + filename_truncated.substr(filename_truncated.length - 15);
    }
    return filename_truncated;
  } else {
    if (type === 'image/jpeg') {
      let filename_truncated = `${name}.jpg`;
      if (filename_truncated.length > 30) {
        filename_truncated =
          filename_truncated.substr(0, 12) + '...' + filename_truncated.substr(filename_truncated.length - 15);
      }
      return filename_truncated;
    } else {
      let filename_truncated = `${name}.png`;
      if (filename_truncated.length > 30) {
        filename_truncated =
          filename_truncated.substr(0, 12) + '...' + filename_truncated.substr(filename_truncated.length - 15);
      }
      return filename_truncated;
    }
  }
}
/**
 * @param  {any[]} errs
 * @param  {any[]} warnings
 */
export function displayWarnings(errs, warnings) {
  if (errs.length) {
    errs.forEach(err => {
      console.error(err);
      toast.error(err);
    });
    return true;
  }
  warnings.forEach(w => {
    console.warn(w);
    toast.warn(w);
  });
}

/**
 * @param  {string} file
 * @param  {string} filename
 * @param  {any} dimensions
 * @param  {number} groupIdx
 * @param  {string} platform
 * @param  {any[]} warnings
 * @param  {any[]} errs
 * @param  {string | undefined} device
 * @param  {any | undefined} options
 */
export function validateAndPrepareImage(
  file,
  filename,
  dimensions,
  groupIdx,
  platform,
  warnings,
  errs,
  device,
  options
) {
  if (!validateImageFile(file, filename, platform, errs)) {
    return false;
  }
  const platforms = ['TW', 'BD', 'GA'];
  if (platform && platforms.includes(platform)) {
    validateComplexDimensions(filename, dimensions, groupIdx, device, options, platform, warnings);
  } else {
    validateImageDimensions(filename, dimensions, groupIdx, platform, warnings);
  }
  return true;
}

function validateImageDimensions(filename, dimensions, groupIndex, platform, warnings) {
  let platformSettings = platforms[platform] || null;

  if (!platformSettings) {
    warnings.push(`Unknown platform ${platform}.`);
    return;
  }

  if (dimensions.w < platformSettings.MIN_WIDTH) {
    warnings.push(
      `Group ${groupIndex + 1}, image ${filename} width ${dimensions.w} is lower than minimum ${
        platformSettings.MIN_WIDTH
      }.`
    );
  } else if (dimensions.h < platformSettings.MIN_HEIGHT) {
    warnings.push(
      `Group ${groupIndex + 1}, image ${filename} height ${dimensions.h} is lower than minimum ${
        platformSettings.MIN_HEIGHT
      }.`
    );
  }
}

function validateImageFile(file, filename, platform, errs) {
  const platformSettings = platforms[platform] || null;
  const filesizeCap = platformSettings && platformSettings.MAX_IMAGE_FILESIZE;
  const allowedFileTypes =
    (platformSettings && platformSettings.ALLOWED_IMAGE_FILETYPES) || DEFAULT_ALLOWED_IMAGE_FILETYPES;

  if (!allowedFileTypes.includes(file.type)) {
    errs.push(`'${file.type}' is not a supported format`);
    return false;
  }

  if (file.size >= filesizeCap) {
    errs.push(`'${filename}' is larger than 30 MB, please pick a smaller file`);
    return false;
  }
  return true;
}

function validateComplexDimensions(filename, dimensions, groupIdx, device, options, platform, warnings) {
  let platformSettings = platforms[platform] || null;

  if (!platformSettings) {
    console.debug(`No platform settings found for ${platform}`);
    warnings.push(`Unknown platform ${platform}.`);
    return;
  }

  console.debug(`Processing image upload for ${platform}`);

  let ratio = dimensions.w / dimensions.h;
  ratio = Math.round(ratio * 10) / 10;

  if (device.toLowerCase() === platformSettings.SMARTPHONE.toLowerCase()) {
    if (
      ratio === platformSettings.SMARTPHONE_ASPECT_RATIO &&
      dimensions.w >= platformSettings.MIN_WIDTH &&
      dimensions.h >= platformSettings.MIN_HEIGHT
    ) {
      options.cropWidth = 0;
      options.cropHeight = 0;
    } else if (
      ratio === platformSettings.SMARTPHONE_ASPECT_RATIO &&
      (dimensions.w < platformSettings.MIN_WIDTH || dimensions.h < platformSettings.MIN_HEIGHT)
    ) {
      options.cropWidth = platformSettings.MIN_WIDTH;
      options.cropHeight = platformSettings.MIN_HEIGHT;
      warnings.push(
        `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be scaled to ${
          platformSettings.MIN_WIDTH
        }x${platformSettings.MIN_HEIGHT}.`
      );
    } else if (ratio !== platformSettings.SMARTPHONE_ASPECT_RATIO) {
      if (dimensions.w > dimensions.h && dimensions.h >= platformSettings.MIN_HEIGHT) {
        options.cropWidth = dimensions.h;
        options.cropHeight = dimensions.h;
        warnings.push(
          `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be cropped to ${
            dimensions.h
          }x${dimensions.h}.`
        );
      } else if (dimensions.w > dimensions.h && dimensions.h < platformSettings.MIN_HEIGHT) {
        options.cropWidth = platformSettings.MIN_WIDTH;
        options.cropHeight = platformSettings.MIN_HEIGHT;
        warnings.push(
          `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will scaled and be cropped to ${
            platformSettings.MIN_HEIGHT
          }x${platformSettings.MIN_HEIGHT}.`
        );
      } else if (dimensions.h > dimensions.w && dimensions.w >= platformSettings.MIN_WIDTH) {
        options.cropWidth = dimensions.w;
        options.cropHeight = dimensions.w;
        warnings.push(
          `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be cropped to ${
            dimensions.w
          }x${dimensions.w}.`
        );
      } else if (dimensions.h > dimensions.w && dimensions.w < platformSettings.MIN_WIDTH) {
        options.cropWidth = platformSettings.MIN_WIDTH;
        options.cropHeight = platformSettings.MIN_HEIGHT;
        warnings.push(
          `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be scaled and cropped to ${
            platformSettings.MIN_WIDTH
          }x${platformSettings.MIN_WIDTH}.`
        );
      }
    }
  }

  if (device.toLowerCase() === platformSettings.DESKTOP.toLowerCase()) {
    if (
      ratio === platformSettings.DESKTOP_ASPECT_RATIO &&
      dimensions.w >= platformSettings.MIN_WIDTH_DESKTOP &&
      dimensions.h >= platformSettings.MIN_HEIGHT
    ) {
      options.cropWidth = 0;
      options.cropHeight = 0;
    } else if (
      ratio === platformSettings.DESKTOP_ASPECT_RATIO &&
      (dimensions.w < platformSettings.MIN_WIDTH_DESKTOP || dimensions.h < platformSettings.MIN_HEIGHT)
    ) {
      options.cropWidth = platformSettings.MIN_WIDTH_DESKTOP;
      options.cropHeight = platformSettings.MIN_HEIGHT;
      warnings.push(
        `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be scaled to ${
          platformSettings.MIN_WIDTH_DESKTOP
        }x${platformSettings.MIN_HEIGHT}.`
      );
    } else if (ratio < platformSettings.DESKTOP_ASPECT_RATIO) {
      if (dimensions.w >= platformSettings.MIN_WIDTH_DESKTOP) {
        options.cropWidth = dimensions.w;
        options.cropHeight = dimensions.w / platformSettings.DESKTOP_ASPECT_RATIO;
        warnings.push(
          `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be cropped to ${
            dimensions.w
          }x${options.cropHeight}.`
        );
      } else {
        options.cropWidth = platformSettings.MIN_WIDTH_DESKTOP;
        options.cropHeight = platformSettings.MIN_HEIGHT;
        warnings.push(
          `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be scaled and cropped to ${
            platformSettings.MIN_WIDTH_DESKTOP
          }x${platformSettings.MIN_HEIGHT}.`
        );
      }
    } else if (ratio > platformSettings.DESKTOP_ASPECT_RATIO) {
      if (dimensions.h >= platformSettings.MIN_HEIGHT) {
        options.cropWidth = dimensions.h * platformSettings.DESKTOP_ASPECT_RATIO;
        options.cropHeight = dimensions.h;
        warnings.push(
          `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be cropped to ${
            options.cropWidth
          }x${dimensions.h}.`
        );
      } else {
        options.cropWidth = platformSettings.MIN_WIDTH_DESKTOP;
        options.cropHeight = platformSettings.MIN_HEIGHT;
        warnings.push(
          `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be scaled and cropped to ${
            platformSettings.MIN_WIDTH_DESKTOP
          }x${platformSettings.MIN_HEIGHT}.`
        );
      }
    }
  }
  if (device === platformSettings.PORTRAIT) {
    if (
      ratio === platformSettings.PORTRAIT_ASPECT_RATIO &&
      dimensions.w >= platformSettings.MIN_WIDTH_PORTRAIT &&
      dimensions.h >= platformSettings.MIN_HEIGHT_PORTRAIT
    ) {
      options.cropWidth = 0;
      options.cropHeight = 0;
    } else if (
      ratio === platformSettings.PORTRAIT_ASPECT_RATIO &&
      (dimensions.w < platformSettings.MIN_WIDTH_PORTRAIT || dimensions.h < platformSettings.MIN_HEIGHT_PORTRAIT)
    ) {
      options.cropWidth = platformSettings.MIN_WIDTH_PORTRAIT;
      options.cropHeight = platformSettings.MIN_HEIGHT_PORTRAIT;
      warnings.push(
        `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be scaled to ${
          platformSettings.MIN_WIDTH_PORTRAIT
        }x${platformSettings.MIN_HEIGHT_PORTRAIT}.`
      );
    } else if (ratio < platformSettings.PORTRAIT_ASPECT_RATIO) {
      if (dimensions.w >= platformSettings.MIN_WIDTH_PORTRAIT) {
        options.cropWidth = dimensions.w;
        options.cropHeight = dimensions.w / platformSettings.PORTRAIT_ASPECT_RATIO;
        warnings.push(
          `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be cropped to ${
            dimensions.w
          }x${options.cropHeight}.`
        );
      } else {
        options.cropWidth = platformSettings.MIN_WIDTH_PORTRAIT;
        options.cropHeight = platformSettings.MIN_HEIGHT_PORTRAIT;
        warnings.push(
          `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be scaled and cropped to ${
            platformSettings.MIN_WIDTH_PORTRAIT
          }x${platformSettings.MIN_HEIGHT_PORTRAIT}.`
        );
      }
    } else if (ratio > platformSettings.PORTRAIT_ASPECT_RATIO) {
      if (dimensions.h >= platformSettings.MIN_HEIGHT_PORTRAIT) {
        options.cropWidth = dimensions.h * platformSettings.PORTRAIT_ASPECT_RATIO;
        options.cropHeight = dimensions.h;
        warnings.push(
          `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be cropped to ${
            options.cropWidth
          }x${dimensions.h}.`
        );
      } else {
        options.cropWidth = platformSettings.MIN_WIDTH_PORTRAIT;
        options.cropHeight = platformSettings.MIN_HEIGHT_PORTRAIT;
        warnings.push(
          `Group ${groupIdx + 1}, image ${filename} ${dimensions.w}x${dimensions.h} will be scaled and cropped to ${
            platformSettings.MIN_WIDTH_PORTRAIT
          }x${platformSettings.MIN_HEIGHT_PORTRAIT}.`
        );
      }
    }
  }
}

export const getFileHash = file => {
  return new Promise((resolve, reject) => {
    const a = new FileReader();
    a.readAsArrayBuffer(file);
    a.onloadend = () => {
      const hash = SHA256(
        btoa(
          new Uint8Array(a.result)
            .reduce((data, byte) => {
              data.push(String.fromCharCode(byte));
              return data;
            }, [])
            .join('')
        )
      ).toString();
      resolve(hash);
    };
    a.onerror = err => {
      Sentry.captureException(err);
      reject(err);
    };
  });
};

export function processFacebookImages(images, index, storeFiles, updateAdsetWithImages) {
  const errs = [];
  const warnings = [];

  const formData = new FormData();
  let basicValidatedImages = [];

  return Promise.all(
    images.map(file => {
      return getImageDimensions(file);
    })
  )
    .then(dimensions => {
      images.forEach((file, i) => {
        const filename_truncated = truncateFileName(file.name);

        if (!validateAndPrepareImage(file, filename_truncated, dimensions[i], index, 'FB', warnings, errs)) {
          return;
        }

        formData.append(i, file);
        basicValidatedImages.push(file);
      });
    })
    .then(() => {
      if (displayWarnings(errs, warnings)) {
        return;
      }
      const options = {
        minWidth: FB.MIN_WIDTH,
        minHeight: FB.MIN_HEIGHT,
        noDownscale: true
      };
      return storeFiles(formData, options);
    })
    .then(filenames => {
      updateAdsetWithImages(index, filenames);
    })
    .catch(err => {
      if (err instanceof ImageLoadError) {
        console.error('Image load error:', err);
        toast.error('Uploaded file is not a valid format. Only JPG, PNG, GIF and Webp files are allowed');
      } else {
        console.error('Unknown error:', err);
      }
      Sentry.captureException(err);
    });
}

export function processFacebookVideos(videos, index, storeVideos, updateAdsetWithVideos) {
  const errs = [];
  const warnings = [];

  const formData = new FormData();
  let basicValidatedImages = [];

  return Promise.all(
    videos.map(file => {
      return getVideoDimensions(file);
    })
  )
    .then(dimensions => {
      videos.forEach((file, i) => {
        const filename_truncated = truncateFileName(file.name);
        //
        // if (!validateAndPrepareVideo(file, filename_truncated, dimensions[i], index, 'FB', warnings, errs)) {
        //   return;
        // }
        formData.append(i, file);
        basicValidatedImages.push(file);
      });
    })
    .then(() => {
      if (displayWarnings(errs, warnings)) {
        return;
      }
      const options = {
        minWidth: FB.MIN_WIDTH,
        minHeight: FB.MIN_HEIGHT,
        noDownscale: true
      };
      return storeVideos(formData, options);
    })
    .then(filenames => {
      console.log({ filenames });

      updateAdsetWithVideos(index, filenames);
    })
    .catch(err => {
      if (err instanceof ImageLoadError) {
        console.error('Video load error:', err);
        toast.error('Uploaded file is not a valid');
      } else {
        console.error('Unknown error:', err);
      }
      Sentry.captureException(err);
    });
}

export function getFullPath(path) {
  if (typeof path === 'string') {
    if (isUrl(path)) {
      // Looks like that's the image URL, not image filename on the server
      return path;
    } else {
      return 'https://s3.amazonaws.com/shinez-pictures/' + path;
    }
  }
  return;
}

export function isUrl(path) {
  if (!path) {
    return false;
  }
  return path.indexOf('http://') !== -1 || path.indexOf('https://') !== -1;
}
