import React from 'react';
import { Grid, Typography, Divider, IconButton, Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PN } from '../Constants';
import { PinterestCreativeGroupBuilder } from './PinterestCreativeGroupBuilder';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const CampaignHeader = styled(Box)(({ theme }) => ({
  backgroundColor: '#3087e7',
  color: 'white',
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2)
}));

const AdGroupContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#fafafa',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2)
}));

const FieldLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5)
}));

export class PinterestBids extends React.Component {
  renderRowName(campaign) {
    const parts = [campaign.country, campaign.device.toLowerCase(), campaign.os, campaign.site];
    return parts.filter(el => el).join(' - ');
  }

  getCampaignKey(campaign) {
    return `${campaign.country}-${campaign.device.toLowerCase()}-${campaign.os || ''}-${campaign.site}`;
  }

  renderAdGroup(campaign, adGroup, adGroupIndex) {
    const campaignKey = `${campaign.country}-${campaign.device.toLowerCase()}-${campaign.os || ''}-${campaign.site}`;

    return (
      <AdGroupContainer>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">Ad Group {adGroupIndex + 1}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} textAlign="right">
            <IconButton
              color="primary"
              size="small"
              onClick={() => this.props.addAdGroup(this.getCampaignKey(campaign))}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={1} textAlign="right">
            <IconButton color="primary" size="small" onClick={() => this.props.duplicateAdGroup(campaign, adGroup)}>
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={1} textAlign="right">
            <IconButton
              color="primary"
              size="small"
              onClick={() => this.props.removeAdGroup(this.getCampaignKey(campaign), adGroupIndex)}
            >
              <RemoveIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FieldLabel variant="subtitle2">Bid Value</FieldLabel>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              inputProps={{ step: PN.BID_STEP, min: 0, max: PN.MAX_BID }}
              value={adGroup.bid}
              onChange={e => {
                const val = parseFloat(e.target.value);
                if (val <= PN.MAX_BID) {
                  this.props.modifyBid(this.getCampaignKey(campaign), adGroupIndex, e, 'bid');
                }
              }}
              onBlur={() => this.props.validateBid(this.getCampaignKey(campaign), adGroupIndex)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FieldLabel variant="subtitle2">Pixel Value</FieldLabel>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={adGroup.pixel}
              inputProps={{ step: PN.PIXEL_STEP, min: 0.01, max: PN.MAX_PIXEL }}
              onChange={e => {
                this.props.modifyBid(this.getCampaignKey(campaign), adGroupIndex, e, 'pixel');
              }}
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          <PinterestCreativeGroupBuilder
            adGroupIdx={`${campaignKey}_${adGroupIndex}`}
            groups={adGroup.creativeGroups || []}
            onAddImages={(groupIdx, blob, type, imageIdx) =>
              this.props.onAddImages(campaignKey, adGroupIndex, groupIdx, blob, type, imageIdx)
            }
            onChangeImageTitle={(groupIdx, imageIdx, value) =>
              this.props.onChangeImageTitle(campaignKey, adGroupIndex, groupIdx, imageIdx, value)
            }
            onChangeImageDescription={(groupIdx, imageIdx, value) =>
              this.props.onChangeImageDescription(campaignKey, adGroupIndex, groupIdx, imageIdx, value)
            }
            removeImage={(groupIdx, imageIdx) => this.props.removeImage(campaignKey, adGroupIndex, groupIdx, imageIdx)}
            duplicateImage={(groupIdx, imageIdx) =>
              this.props.duplicateImage(campaignKey, adGroupIndex, groupIdx, imageIdx)
            }
            populateText={(groupIdx, imageIdx) =>
              this.props.populateText(campaignKey, adGroupIndex, groupIdx, imageIdx)
            }
          />
        </Box>
      </AdGroupContainer>
    );
  }

  render() {
    const campaigns = this.props.bids || [];

    return (
      <Grid container spacing={2}>
        {campaigns.map((campaign, campaignIndex) => (
          <Grid item xs={12} key={`campaign-${campaignIndex}`}>
            <CampaignHeader>
              <Typography variant="subtitle1">Campaign: {this.renderRowName(campaign)}</Typography>
            </CampaignHeader>

            {campaign.adGroups.map((adGroup, adGroupIndex) => (
              <React.Fragment key={`${campaignIndex}-group-${adGroupIndex}`}>
                {this.renderAdGroup(campaign, adGroup, adGroupIndex, campaignIndex)}
                {adGroupIndex < campaign.adGroups.length - 1 && <Divider style={{ margin: '20px 0' }} />}
              </React.Fragment>
            ))}

            <Divider style={{ margin: '20px 0' }} />
          </Grid>
        ))}
      </Grid>
    );
  }
}
