import React, { Component } from 'react';
import { PLATFORMS, PN } from '../Constants';
import { Grid, CardContent, TextField, CardActions, CardHeader, Tooltip, Input, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { getFullPath } from '../utils/imageUploadUtils';
import { StyledCard, StyledCardMedia } from '../SharedStyles';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import ClearIcon from '@mui/icons-material/Clear';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CropIcon from '@mui/icons-material/Crop';
import CropWindow from '../CropWindow';
import PublishIcon from '@mui/icons-material/Publish';

class PinterestCreativeGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyDefaultText: '',
      anchorEl: null,
      images: [],
      uploadedImages: [],
      src: [],
      showCropWindow: false,
      showCropper: false,
      selectedImageIndex: 0,
      originFileName: [],
      textTooltip: [],
      titleTooltip: [],
      titleTooltipMobile: [],
      isFocusedText: Array(props.group.images.length).fill(false),
      isFocusedDescription: Array(props.group.images.length).fill(false)
    };
  }

  getKey(base, imageIndex) {
    return base + this.props.index + '_' + imageIndex;
  }

  showHelperDescription = idx => {
    const description = this.props.group.descriptions[idx] || '';
    return `${description.length}/${PN.MAX_DESCRIPTION_CHARS}`;
  };

  showHelperTitle = idx => {
    const title = this.props.group.titles[idx] || '';
    return `${title.length}/${PN.MAX_TITLE_CHARS}`;
  };

  cancelCropWindow() {
    this.setState({ showCropper: false, showCropWindow: false });
  }

  onSelectFileCrop = async ad => {
    const blob = await fetch(`/api/getImg?img=${encodeURIComponent(ad)}`).then(r => r.blob());
    this.readAsDataURL(blob).then(res => {
      this.setState({ src: res, showCropper: true });
    });
  };
  applyCropWindow(images) {
    images.forEach((image, imageIndex) => {
      for (const type in image.crops) {
        if (image.crops[type] !== null && image.crops[type].blob instanceof Blob) {
          const blob = image.crops[type].blob;
          this.props.onAddImages(blob, type, imageIndex);
        }
      }
    });
    this.setState({ showCropper: false, showCropWindow: false });
  }
  uploadMultipleFiles = e => {
    console.log('uploadMultipleFiles', {
      adGroupIdx: this.props.adGroupIdx,
      groupIdx: this.props.index,
      files: e.target.files
    });

    const fileArray = Array.from(e.target.files).map(file => URL.createObjectURL(file));

    this.setState({
      imageFiles: fileArray,
      showCropWindow: true
    });
  };

  readAsDataURL = blob => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  onClose = () => {
    this.setState({ showCropWindow: false, showCropper: false, src: '' });
  };

  renderImage(path, idx) {
    const fullPath = getFullPath(path);
    return (
      <React.Fragment key={this.getKey('renderCreativeCard', idx)}>
        <Grid item sm={4}>
          <StyledCard>
            <IconButton
              style={{ backgroundColor: 'transparent' }}
              title="Delete ad"
              fontSize="small"
              aria-label="delete"
              key={this.getKey('imageRemoveButton', idx)}
              onClick={imageIdx => this.props.removeImage(idx, imageIdx)}
              sx={{ margin: '-30px 0 -55px 150px' }}
              size="large"
            >
              <Tooltip title="Delete">
                <ClearIcon />
              </Tooltip>
            </IconButton>
            <CardHeader
              subheader={
                <TextField
                  key={this.getKey('imageTitle', idx)}
                  value={this.props.group.titles?.[idx] || ''}
                  onChange={e => {
                    const value = e.target.value;
                    console.log('TextField onChange', { idx, value });
                    this.props.onChangeImageTitle(idx, value);
                  }}
                  maxLength={PN.MAX_TITLE_CHARS}
                  variant="standard"
                  multiline
                  fullWidth
                  placeholder="Title"
                  helperText={this.showHelperTitle(idx)}
                  inputProps={{
                    maxLength: PN.MAX_TITLE_CHARS
                  }}
                />
              }
            />
            <StyledCardMedia key={this.getKey('cardImage', idx)} image={fullPath} title="Example" />
            <CardContent>
              <TextField
                key={this.getKey('imageDescription', idx)}
                value={this.props.group.descriptions?.[idx] || ''}
                onChange={e => {
                  const value = e.target.value;
                  this.props.onChangeImageDescription(idx, value);
                }}
                maxLength={PN.MAX_DESCRIPTION_CHARS}
                variant="standard"
                multiline
                minRows={1}
                maxRows={10}
                fullWidth
                placeholder="Description"
                helperText={this.showHelperDescription(idx)}
                inputProps={{
                  maxLength: PN.MAX_DESCRIPTION_CHARS
                }}
              />
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                color="primary"
                key={this.getKey('populateButton', idx)}
                onClick={imageIdx => this.props.populateText(idx, imageIdx)}
                title="Populate all texts in group"
                size="large"
              >
                <TextRotationNoneIcon />
              </IconButton>
              <IconButton
                color="primary"
                key={this.getKey('imageDuplicateButton', idx)}
                onClick={imageIdx => this.props.duplicateImage(idx, imageIdx)}
                size="large"
              >
                <FileCopyIcon />
              </IconButton>
              <IconButton
                aria-label="menu"
                color="primary"
                key={this.getKey('cropButton', idx)}
                onClick={() => this.onSelectFileCrop(path, idx)}
                title="Crop image"
                size="large"
              >
                <Tooltip title="Crop image">
                  <CropIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </CardActions>
          </StyledCard>
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment key={this.getKey('creativeGroup', this.props.index)}>
        <div style={{ marginTop: '15px' }}>
          <Grid item container spacing={1} alignItems={'center'} sm={12} style={{ backgroundColor: '#e9f4ff' }}>
            <Grid item sm={4}>
              <Typography variant="subtitle2" color="black" gutterBottom>
                Pins
              </Typography>
            </Grid>
            <Grid item sm={8} align={'right'}>
              <label htmlFor={`addImagesButton-${this.props.adGroupIdx}-${this.props.index}`}>
                <IconButton color="black" component="span" size="large">
                  <PublishIcon />
                </IconButton>
              </label>
              <Input
                type="file"
                id={`addImagesButton-${this.props.adGroupIdx}-${this.props.index}`}
                onChange={this.uploadMultipleFiles}
                inputProps={{ multiple: true }}
                onClick={e => {
                  e.target.value = null;
                }}
                style={{ display: 'none' }}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container direction="row" spacing={1}>
          {this.state.showCropWindow ? (
            <CropWindow
              fromInsights={false}
              notRequired={true}
              desktopAspectRatio={PN.DESKTOP_ASPECT_RATIO}
              smartphoneAspectRatio={PN.SMARTPHONE_ASPECT_RATIO}
              data={this.state.imageFiles}
              onClose={() => this.cancelCropWindow()}
              onSave={images => this.applyCropWindow(images)}
              index={this.props.index}
              group={this.props.group.images}
              platform={PLATFORMS.PINTEREST}
            />
          ) : null}
          {this.props.group.images.map((path, i) => this.renderImage(path, i))}
        </Grid>
      </React.Fragment>
    );
  }
}

export default PinterestCreativeGroup;
