import React, { createRef, StrictMode, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { unregister } from './registerServiceWorker';
import { isLoggedIn, requireAuth, getUserInfo } from './utils/AuthService';
import * as Sentry from '@sentry/react';

import Layout from './Layout';
import Login from './components/Login';
import Callback from './components/Callback';
import NativeCreate from './components/NativeCreate';
import GetAdLink from './components/GetAdLink';
import FacebookCreativesPage from './components/pages/FacebookCreativesPage';
import CollageMakerPage from './components/pages/CollageMaker/CollageMakerPage';
import UnauthorizedPage from './components/pages/UnauthorizedPage';
import ServicePage from './components/pages/ServicePage';
import Loader from './components/Loader';

Sentry.init({
  dsn: 'https://cf9cc1d07e70f28573381c03e1d2a6a3@o4505878717267968.ingest.sentry.io/4505879030005760',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.05, // Capture 5% of the transactions, reduced for production!
  sampleRate: 0.15,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV === 'production'
});

const Root = () => {
  const traces = createRef();
  const nc = createRef();
  const [taskCode, setTaskCode] = useState(0);
  const [taskView, setTaskView] = useState(false);
  const [devSkipCreate, setDevSkipCreate] = useState(false);
  const [allowPageSelection, setAllowPageSelection] = useState(false);
  const [user, setUser] = useState();

  const onTasks = () => {
    if (taskView || taskCode) setTaskView(!taskView);
  };

  const onTraceChanged = code => {
    setTaskCode(code);
    if (!code && taskView) setTaskView(false);
  };

  const toggleAllowPageSelection = () => {
    setAllowPageSelection(!allowPageSelection);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const data = await getUserInfo();
      return data;
    };
    fetchUser().then(data => setUser(data));
  }, []);

  return (
    <>
      <BrowserRouter onEnter={requireAuth}>
        {!user ? (
          <div className={!user ? 'placeholder-wrapper isActive' : 'placeholder-wrapper'}>
            <Loader />
          </div>
        ) : (
          <Routes>
            {isLoggedIn() && !user.error ? (
              <Route
                path="/"
                element={
                  <Layout
                    onTasks={onTasks}
                    onTest={() => nc.current.onTest()}
                    taskCode={taskCode}
                    devSkipCreate={devSkipCreate}
                    setDevSkipCreate={setDevSkipCreate}
                    toggleAllowPageSelection={toggleAllowPageSelection}
                    allowPageSelection={allowPageSelection}
                    taskView={taskView}
                    traces={traces}
                    onTraceChanged={onTraceChanged}
                    user={user}
                  />
                }
              >
                <Route
                  index
                  element={
                    user.app_metadata.spm_access_block_with_message ? (
                      <ServicePage />
                    ) : user.app_metadata.platforms.length <= 0 &&
                      (user.app_metadata.spm_review_access || user.app_metadata.spm_upload_access) ? (
                      <FacebookCreativesPage />
                    ) : user.app_metadata.platforms.length <= 0 && user.app_metadata.spm_collage_access ? (
                      <CollageMakerPage user={user} />
                    ) : (
                      <NativeCreate
                        ref={nc}
                        trace={v => traces.current.trace(v)}
                        autoReload={() => traces.current.autoReload()}
                        isDevSkipCreate={() => devSkipCreate}
                        allowPageSelection={allowPageSelection}
                      />
                    )
                  }
                />
                {user.app_metadata.spm_access_block_with_message ? null : (
                  <>
                    <Route path="adlink" element={<GetAdLink />} />
                    <Route path="fbcreatives" element={<FacebookCreativesPage />} />
                    <Route path="collage-maker" element={<CollageMakerPage user={user} />} />
                    <Route path="unauthorized" element={<UnauthorizedPage />} />
                  </>
                )}
              </Route>
            ) : (
              <Route index element={user.error === 'Unauthorized' ? <UnauthorizedPage /> : <Login />} />
            )}
            <Route path="callback" element={<Callback />} />
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
      </BrowserRouter>
    </>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StrictMode>
    <Root />
  </StrictMode>
);
unregister();
